import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { AlertDialogTrigger } from '@radix-ui/react-alert-dialog';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  description: string;
  onConfirm: () => void;
  redirect?: string;
  children?: ReactElement;
  type?: 'delete';
};

export const ConfirmationModal = ({
  title,
  description,
  onConfirm,
  redirect,
  children,
  type,
}: Props): ReactElement => {
  const navigate = useNavigate();
  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full">{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Anuluj</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              onConfirm();
              !!redirect && navigate(redirect);
            }}
            className={clsx(type === 'delete' && 'bg-red-500')}
          >
            Kontynuuj
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
