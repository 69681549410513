import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { CircleDot } from 'lucide-react';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { TicketDetails } from '@/types/ticketDetails';

export const columns: ColumnDef<TicketDetails>[] = [
  {
    accessorKey: 'index',
    header: 'Pozycja',
    cell: ({ row }) => <span>{row.original.index}</span>,
  },
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClassName = '';
      let statusLabel = '';

      switch (row.original.status) {
        case 'unavailable':
          statusClassName = 'border-neutral-400 text-neutral-500';
          statusLabel = 'Niedostępny';
          break;
        case 'onsale':
          statusClassName = 'border-cyan-600 text-cyan-600';
          statusLabel = 'W sprzedaży';
          break;
        case 'soldout':
          statusClassName = 'border-emerald-600 text-emerald-600';
          statusLabel = 'Wyprzedany';
          break;
      }

      return (
        <p
          className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 `}
        >
          <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
        </p>
      );
    },
  },
  {
    accessorKey: 'type',
    header: 'Rodzaj',
    cell: ({ row }) => {
      const ticketType =
        row.original.type === 'digital' ? 'Cyfrowy' : 'Fizyczny';

      return <p>{ticketType}</p>;
    },
  },
  {
    accessorKey: 'range',
    header: 'Wstęp',
    cell: ({ row }) => {
      let wstepLabel = '';

      switch (row.original.range) {
        case 'full':
          wstepLabel = 'Pełny';
          break;
        case 'first_day':
          wstepLabel = 'Jednodniowy (I dzień)';
          break;
        case 'second_day':
          wstepLabel = 'Jednodniowy (II dzień)';
          break;
      }

      return <p>{wstepLabel}</p>;
    },
  },
  {
    accessorKey: 'price',
    header: 'Cena',
    cell: ({ row }) => {
      const formattedPrice = row.original.price.toFixed(2);

      return (
        <p className="text-lg">
          {formattedPrice}{' '}
          <span className="text-slate-500 text-sm font-normal leading-tight">
            PLN
          </span>
        </p>
      );
    },
  },
  {
    accessorKey: 'income',
    header: 'Przychód',
    cell: ({ row }) => {
      const formattedPrice = row.original.income.toFixed(2);

      return (
        <p className="text-lg">
          {formattedPrice}{' '}
          <span className="text-slate-500 text-sm font-normal leading-tight">
            PLN
          </span>
        </p>
      );
    },
  },
  {
    accessorKey: 'amount',
    header: 'Liczba biletów',
    cell: ({ row }) => {
      return <p className="text-lg text-center">{row.original.amount}</p>;
    },
  },
  {
    accessorKey: 'sold',
    header: 'Liczba sprzedanych biletów',
    cell: ({ row }) => {
      const percent = ((row.original.sold / row.original.amount) * 100).toFixed(
        2,
      );

      return (
        <p className="text-lg text-center">
          {row.original.sold}{' '}
          <span className="text-slate-500 text-sm font-normal leading-tight">
            ({percent})%
          </span>
        </p>
      );
    },
  },
];
