import {
  useDeleteTicketMutation,
  useUpdateTicketMutation,
} from '@/api/tickets';
import { Ticket } from '@/types/ticket';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import {
  CircleDot,
  Info,
  MoreHorizontal,
  PlusCircle,
  Trash,
} from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { AddEditTicket } from '../ticket/AddEditTicket';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Switch } from '../ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

export const columns: ColumnDef<Ticket>[] = [
  {
    accessorKey: 'image',
    header: 'Zdjęcie',
    cell: ({ row }) =>
      row.original.image ? (
        <img
          src={row.original.image}
          alt="Ticket image"
          className="w-20 h-20 rounded-md"
        />
      ) : (
        <div className="flex items-center justify-center w-20 h-20 rounded-md bg-slate-100 border border-slate-400 text-slate-700">
          <Button
            variant="ghost"
            className="flex items-center gap-x-1 pl-1.5 pr-2 py-1.5"
          >
            <PlusCircle className="w-4 h-4" />
            <p>Dodaj</p>
          </Button>
        </div>
      ),
  },
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClassName = '';
      let statusLabel = '';

      switch (row.original.status) {
        case 'unavailable':
          statusClassName = 'border-neutral-400 text-neutral-500';
          statusLabel = 'Niedostępny';
          break;
        case 'onsale':
          statusClassName = 'border-cyan-600 text-cyan-600';
          statusLabel = 'W sprzedaży';
          break;
        case 'soldout':
          statusClassName = 'border-emerald-600 text-emerald-600';
          statusLabel = 'Wyprzedany';
          break;
      }

      return (
        <p
          className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 `}
        >
          <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
        </p>
      );
    },
  },
  {
    accessorKey: 'type',
    header: 'Rodzaj',
    cell: ({ row }) => {
      const ticketType =
        row.original.type === 'digital' ? 'Cyfrowy' : 'Fizyczny';

      return <p className="text-sm font-normal leading-tight">{ticketType}</p>;
    },
  },
  {
    accessorKey: 'range',
    header: 'Wstęp',
    cell: ({ row }) => {
      let wstepLabel = '';

      switch (row.original.range) {
        case 'full':
          wstepLabel = 'Pełny';
          break;
        case 'first_day':
          wstepLabel = 'Jednodniowy (I dzień)';
          break;
        case 'second_day':
          wstepLabel = 'Jednodniowy (II dzień)';
          break;
        case 'any':
          wstepLabel = 'Dowolny';
          break;
      }

      return <p className="text-sm font-normal leading-tight">{wstepLabel}</p>;
    },
  },
  {
    accessorKey: 'amount',
    header: 'Ilość biletów',
  },
  {
    accessorKey: 'sold',
    header: 'Ilość sprzedanych',
  },
  {
    accessorKey: 'price',
    header: 'Cena biletu',
    cell: ({ row }) => {
      const formattedPrice = row.original.price.toFixed(2);

      return (
        <p className="text-sm font-normal leading-tight">
          {formattedPrice}{' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
      );
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [availablility, setAvailablility] = useState(
        !!row?.original?.available,
      );
      const [updateTicket] = useUpdateTicketMutation();
      const [deleteTicket] = useDeleteTicketMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.event.id}/bilety/${row.original.id}`}
                  className="w-full text-sm font-medium leading-tight"
                >
                  Informacje o bilecie
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.event.id}/bilety/${row.original.id}/zakupione`}
                  className="w-full text-sm font-medium leading-tight"
                >
                  Zakupione bilety
                </Link>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full px-2.5">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          defaultChecked={availablility}
                          onCheckedChange={() => {
                            const updatedAvailable = !availablility;
                            setAvailablility(updatedAvailable);
                            updateTicket({
                              id: row.original.id,
                              available: updatedAvailable,
                            });
                          }}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        biletu
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <AddEditTicket type="update" ticket={row.original} />
              <ConfirmationModal
                type="delete"
                title="Czy na pewno chcesz usunąć ten bilet?"
                description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
              Twojego biletu i usunięcie związanych z nim danych z naszych
              serwerów."
                onConfirm={() => deleteTicket({ ticketId: row.original.id })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
