import { useGetEventsQuery } from '@/api/events';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { AddEditEvent } from './AddEditEvent';
import { DataTable } from './DataTable';
import { columns } from './columns';

export const Events = (): ReactElement => {
  const { data, isLoading } = useGetEventsQuery();
  const events = data?.events?.length;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          Wydarzenia
        </h1>
        {!!events && <AddEditEvent light />}
      </div>

      {!!events ? (
        <DataTable columns={columns} data={data.events} />
      ) : isLoading ? null : (
        <NotFound
          description="Nie znaleziono żadnego wydarzenia"
          element={<AddEditEvent />}
        />
      )}
    </div>
  );
};
