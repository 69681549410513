import { useDeleteEventMutation, useUpdateEventMutation } from '@/api/events';
import { Event } from '@/types/event';
import { trimId } from '@/utils/trimId';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import {
  Calendar,
  CircleDot,
  Info,
  MoreHorizontal,
  ShoppingCart,
  Ticket,
  Trash,
} from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Switch } from '../ui/switch';
import { AddEditEvent } from './AddEditEvent';
import { getEventAvailability } from './get-event-availability';

export const columns: ColumnDef<Event>[] = [
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={table.getIsAllPageRowsSelected()}
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'from',
    header: 'Data',
    cell: ({ row }) => {
      const fromDate = new Date(row.original.from);
      const toDate = new Date(row.original.to);

      const isSameDate = fromDate.toDateString() === toDate.toDateString();

      const firsDay = format(fromDate, 'dd/MM/yyyy', { locale: pl });
      const lastDay = format(toDate, 'dd/MM/yyyy', { locale: pl });

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          {isSameDate ? (
            firsDay
          ) : (
            <p className="whitespace-nowrap">
              {firsDay} - {lastDay}
            </p>
          )}
        </span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const { statusClassName, statusLabel } = getEventAvailability(
        row.original,
      );

      return (
        <p
          className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 whitespace-nowrap`}
        >
          <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
        </p>
      );
    },
  },
  {
    accessorKey: 'totalAvailableTickets',
    header: 'Całkowita liczba biletów',
  },
  {
    accessorKey: 'totalSoldTickets',
    header: 'Liczba sprzedanych biletów',
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [availablility, setAvailablility] = useState(
        !!row?.original?.available,
      );
      const [updateEvent] = useUpdateEventMutation();
      const [deleteEvent] = useDeleteEventMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.id}/bilety`}
                  className="flex items-center w-full"
                >
                  <Ticket className="w-4 h-4 mr-1" />
                  Bilety
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.id}/zamowienia`}
                  className="flex items-center w-full"
                >
                  <ShoppingCart className="w-4 h-4 mr-1" />
                  Zamówienia
                </Link>
              </DropdownMenuItem>
              {/* <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.id}/arts`}
                  className="flex items-center w-full"
                >
                  <MessageSquare className="w-4 h-4 mr-1" />
                  Artykuły
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link
                  to={`/wydarzenia/${row.original.id}/statystyki`}
                  className="flex items-center w-full"
                >
                  <BarChart className="w-4 h-4 mr-1" />
                  Statystyki
                </Link>
              </DropdownMenuItem> */}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <DropdownMenuItem>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild className="w-full px-2.5">
                      <div className="flex justify-between items-center w-full">
                        <div className="flex items-center">
                          <p>Dostępność</p>
                          <Info className="w-3.5 h-3.5 ml-1 text-slate-400" />
                        </div>
                        <Switch
                          key={row.original.id}
                          defaultChecked={availablility}
                          onCheckedChange={() => {
                            const updatedAvailable = !availablility;
                            setAvailablility(updatedAvailable);
                            updateEvent({
                              eventId: row.original.id,
                              body: { available: updatedAvailable },
                            });
                          }}
                          className="w-11 h-6"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Wyłączenie tej opcji uniemożliwi użytkownikom zakup
                        biletu
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <AddEditEvent type="update" event={row.original} />
              <ConfirmationModal
                type="delete"
                title="Czy na pewno chcesz usunąć to wydarzenie?"
                description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
                Twojego wydarzenia i usunięcie związanych z nim danych z naszych
                serwerów."
                onConfirm={() => deleteEvent({ eventId: row.original.id })}
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
