import { useGetEventByIdQuery } from '@/api/events';
import { useGetAllTicketTagsQuery } from '@/api/ticketTag';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../NotFound';
import { DataTable } from '../eventsView/DataTable';
import { Loading } from '../login/Loading';
import { AddEditTicketTag } from './AddEditTicketTags';
import { columns } from './columns';

export const TicketTags = (): ReactElement => {
  const params = useParams();
  const eventId = params['eventId'];

  const { data: eventData } = useGetEventByIdQuery(
    { eventId },
    { skip: !eventId },
  );

  const event = eventData?.event;

  const { data, isLoading } = useGetAllTicketTagsQuery(
    {
      eventId: event!.id!,
    },
    { skip: !event },
  );

  const ticketTags = data?.tags?.length;

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          Tagi biletów
        </h1>
        {!!ticketTags && <AddEditTicketTag light />}
      </div>
      {ticketTags ? (
        !!event && (
          <DataTable columns={columns(event)} data={data?.tags ?? []} />
        )
      ) : (
        <NotFound
          description="Nie znaleziono żadnego tagu"
          element={<AddEditTicketTag />}
        />
      )}
    </div>
  );
};
