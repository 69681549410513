import {
  useCreateTicketTagMutation,
  useUpdateTicketTagMutation,
} from '@/api/ticketTag';
import { useGetTicketByIdQuery } from '@/api/tickets';
import { TicketTag } from '@/types/ticketTag';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Plus } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

const Schema = yup.object({
  name: yup.string().max(60, {
    message: 'Nazwa może zawierać maksymalnie 60 znaków',
  }),
  type: yup
    .string()
    .oneOf(['DISCOUNT', 'TICKET_ENTRY_RULE'])
    .required('Tag jest wymagany'),
});

type Props = {
  light?: boolean;
  type?: 'update';
  ticketTag?: TicketTag;
};

export const AddEditTicketTag = ({
  light,
  type,
  ticketTag,
}: Props): ReactElement => {
  const { eventId } = useParams();

  const [open, setOpen] = useState(false);
  const [createTicketTag] = useCreateTicketTagMutation();
  const [updateTicketTag] = useUpdateTicketTagMutation();

  const isUpdate = type === 'update';

  if (isUpdate && !ticketTag) {
    const { ticketId } = useParams();
    const { data } = useGetTicketByIdQuery(
      { ticketId: ticketId! },
      { skip: !ticketId },
    );
    ticketTag = data?.ticket as TicketTag | undefined;
  }

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: ticketTag ? ticketTag.name : '',
      type: ticketTag ? ticketTag.type : 'DISCOUNT',
    },
  });

  const { watch } = form;
  const nameFieldValue = watch('name') || ticketTag?.name || '';

  useEffect(() => {
    if (ticketTag) {
      form.reset({
        ...ticketTag,
      } as unknown as yup.InferType<typeof Schema>);
    }
  }, [ticketTag, form.reset]);

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();

      if (!isValid) return;

      if (type === 'update') {
        await updateTicketTag({
          ticketTagId: ticketTag?.id!,
          name: values.name ?? '',
          eventId: eventId!,
        });
      } else {
        await createTicketTag({
          name: values.name!,
          type: values.type,
          eventId: eventId!,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj tag</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-160 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose">
            {isUpdate ? 'Edytuj tag' : 'Dodaj tag'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <div className="flex items-center gap-x-4">
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription className="whitespace-nowrap">
                        {nameFieldValue.length} / 60 znaków
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {type !== 'update' && (
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Kategoria</FormLabel>
                      <div className="flex gap-x-2 w-full">
                        <FormDescription className="whitespace-nowrap w-full">
                          <Select
                            onValueChange={(
                              value: 'DISCOUNT' | 'TICKET_ENTRY_RULE',
                            ) => {
                              form.setValue('type', value);
                            }}
                            defaultValue={ticketTag?.type ?? 'DISCOUNT'}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select category" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup defaultValue="DISCOUNT">
                                <SelectItem value="DISCOUNT">Zniżka</SelectItem>
                                <SelectItem value="TICKET_ENTRY_RULE">
                                  Reguła wejścia
                                </SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormDescription>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz tag
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
