export enum EventStatus {
  PAST = 'past',
  ONGOING = 'ongoing',
  SOON = 'soon',
  UNAVAILABLE = 'unavailable',
}

export type Event = {
  id: string;
  name: string;
  from: string;
  to: string;
  available: boolean;
  totalAvailableTickets: number;
  totalSoldTickets: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  status?: EventStatus;
};
