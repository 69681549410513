import { useDeleteTicketTagMutation } from '@/api/ticketTag';
import { Event } from '@/types/event';
import { TicketTag } from '@/types/ticketTag';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { AddEditTicketTag } from './AddEditTicketTags';

export const columns = (event: Event): ColumnDef<TicketTag>[] => [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'type',
    header: 'Typ biletu',
    cell: ({ row }) => {
      const type = row.original.type;
      return type === 'DISCOUNT' ? 'Zniżka' : 'Reguła wejścia';
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteTicketTag] = useDeleteTicketTagMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <AddEditTicketTag type="update" ticketTag={row.original} />
              <ConfirmationModal
                type="delete"
                title="Czy na pewno chcesz usunąć ten bilet?"
                description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
              Twojego biletu i usunięcie związanych z nim danych z naszych
              serwerów."
                onConfirm={() =>
                  deleteTicketTag({
                    eventId: event.id,
                    ticketTagId: row.original.id,
                  })
                }
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
