import { useGetTicketGeneratorQuery } from '@/api/ticketGenerator';
import { useSelectedEvent } from '@/slices/credentials/selectors';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { DataTable } from '../eventMenu/DataTable';
import { AddEditTicketGenerator } from './AddEditTicketGenerator';
import { columns } from './columns';

export const TicketGenerator = (): ReactElement => {
  const event = useSelectedEvent();
  const { data } = useGetTicketGeneratorQuery(
    {
      eventId: event?.id ?? '',
    },
    { skip: !event },
  );
  const ticketGenerators = data?.ticketGenerator.length;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          Generator biletów
        </h1>
        {!!ticketGenerators && <AddEditTicketGenerator light />}
      </div>
      {ticketGenerators ? (
        <DataTable columns={columns} data={data?.ticketGenerator ?? []} />
      ) : (
        <NotFound
          description="Nie znaleziono żadnych wygenerowanych biletów"
          element={<AddEditTicketGenerator />}
        />
      )}
    </div>
  );
};
