import clsx from 'clsx';
import { CircleDot } from 'lucide-react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { useEffect } from 'react';

const statusColors: Record<string, string> = {
  awaiting: 'neutral-400',
  paid: 'cyan-600',
  delivered: 'emerald-600',
  generated: 'neutral-400',
  cancelled: 'red-500',
  not_paid: 'yellow-500',
  refund_requested: 'orange-500',
  refunded: 'green-500',
};

const statusLabels: Record<string, string> = {
  awaiting: 'Oczekujące',
  paid: 'Opłacone',
  delivered: 'Dostarczone',
  generated: 'Wygenerowane',
  cancelled: 'Anulowane',
  not_paid: 'Nieopłacone',
  refund_requested: 'Żądanie zwrotu',
  refunded: 'Zwrócone',
};

const orderStatuses = Object.keys(statusLabels);
type Props = {
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
};

export const OrderStatusSelect = ({
  selectedStatus,
  setSelectedStatus,
}: Props) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const statusLabelStyle =
    'inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1';

  // useEffect(() => {
  //   const statusParam = searchParams.get('status');
  //   if (statusParam && Object.keys(statusColors).includes(statusParam)) {
  //     setSelectedStatus(statusParam.trim());
  //   } else {
  //     setSelectedStatus('');
  //   }
  // }, [searchParams]);

  const selectStatus = (status: string) => {
    const newStatus = status === selectedStatus ? '' : status;
    setSelectedStatus(newStatus);
    // const newSearchParams = new URLSearchParams(searchParams);
    // newStatus
    //   ? newSearchParams.set('status', newStatus)
    //   : newSearchParams.delete('status');
    // setSearchParams(newSearchParams);
  };

  useEffect(() => {}, [selectedStatus]);

  return (
    <Select onValueChange={(value) => selectStatus(value)}>
      <SelectTrigger value={selectedStatus}>
        {!!selectedStatus ? (
          <SelectValue placeholder={selectedStatus} />
        ) : (
          <SelectValue placeholder="Status" />
        )}
      </SelectTrigger>
      <SelectContent className="p-0">
        {orderStatuses.map((status) => (
          <SelectItem key={status} value={status}>
            <p
              className={clsx(
                `border-${statusColors[status]} text-${statusColors[status]}`,
                statusLabelStyle,
              )}
            >
              <CircleDot className="size-2.5" />
              {statusLabels[status]}
            </p>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
