import { eventsApiEndpoints } from '@/api/events';
import { Event } from '@/types/event';
import { createSlice } from '@reduxjs/toolkit';

export type EventsStore = {
  selectedEvent?: Event;
  events: Event[];
};

const initialState: EventsStore = {
  selectedEvent: undefined,
  events: [],
};

const eventSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(
        eventsApiEndpoints.getEvents.matchFulfilled,
        (state, { payload }) => {
          state.events = payload.events;
        },
      )
      .addMatcher(
        eventsApiEndpoints.getEventById.matchFulfilled,
        (state, { payload }) => {
          state.selectedEvent = payload.event;
        },
      );
  },
  name: 'events',
  initialState,
  reducers: {},
});

export const eventsReducer = eventSlice.reducer;
