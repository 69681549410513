import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const tabsList = [
  'bilety',
  'generator-biletow',
  'tagi-biletow',
  'promocje',
  'horeca',
  'mailing',
  'dostawa',
  'regula-wejscia',
] as const;

type TabValue = (typeof tabsList)[number];

export const EventMenu = () => {
  const navigate = useNavigate();
  const params = useParams();
  const handleTabClick = (value: TabValue) =>
    navigate(`/wydarzenia/${params['eventId']}/${value}`);
  const [activeTab, setActiveTab] = useState<TabValue>('bilety');

  useEffect(() => {
    const pathname = window.location.pathname;
    const currentTab = pathname.split('/').pop();
    const tabs: typeof tabsList = [
      'bilety',
      'generator-biletow',
      'tagi-biletow',
      'promocje',
      'horeca',
      'mailing',
      'dostawa',
      'regula-wejscia',
    ];
    if (tabs.includes(currentTab as TabValue)) {
      setActiveTab(currentTab as TabValue);
    }
  });

  const activeTabStyle = 'bg-slate-200';
  const inactiveTabStyle = 'px-5 py-2 rounded-md';
  const tabStyle = (tab: TabValue) =>
    clsx(activeTab === tab && activeTabStyle, inactiveTabStyle);

  return (
    <div className="flex flex-col w-full">
      <Tabs defaultValue="bilety" className="w-full">
        <TabsList className="mx-4 2xl:mx-10 pt-10 flex whitespace-nowrap overflow-x-auto">
          <TabsTrigger
            value="bilety"
            onClick={() => handleTabClick('bilety')}
            className={tabStyle('bilety')}
          >
            Bilety
          </TabsTrigger>
          <TabsTrigger
            value="generator-biletow"
            onClick={() => handleTabClick('generator-biletow')}
            className={tabStyle('generator-biletow')}
          >
            Generator biletów
          </TabsTrigger>
          <TabsTrigger
            value="tagi-biletow"
            onClick={() => handleTabClick('tagi-biletow')}
            className={tabStyle('tagi-biletow')}
          >
            Tagi biletów
          </TabsTrigger>
          <TabsTrigger
            value="promocje"
            onClick={() => handleTabClick('promocje')}
            className={tabStyle('promocje')}
          >
            Promocje
          </TabsTrigger>
          <TabsTrigger
            value="horeca"
            onClick={() => handleTabClick('horeca')}
            className={tabStyle('horeca')}
          >
            HoReCa
          </TabsTrigger>
          <TabsTrigger
            value="mailing"
            onClick={() => handleTabClick('mailing')}
            className={tabStyle('mailing')}
          >
            Mailing
          </TabsTrigger>
          <TabsTrigger
            value="dostawa"
            onClick={() => handleTabClick('dostawa')}
            className={tabStyle('dostawa')}
          >
            Dostawa
          </TabsTrigger>
          <TabsTrigger
            value="regula-wejscia"
            onClick={() => handleTabClick('regula-wejscia')}
            className={tabStyle('regula-wejscia')}
          >
            Reguła wejścia
          </TabsTrigger>
        </TabsList>
        <TabsContent value="bilety">
          <Outlet />
        </TabsContent>
        <TabsContent value="generator-biletow">
          <Outlet />
        </TabsContent>
        <TabsContent value="tagi-biletow">
          <Outlet />
        </TabsContent>
        <TabsContent value="promocje">
          <Outlet />
        </TabsContent>
        <TabsContent value="horeca">
          <Outlet />
        </TabsContent>
        <TabsContent value="mailing">
          <Outlet />
        </TabsContent>
        <TabsContent value="dostawa">
          <Outlet />
        </TabsContent>
        {/* <TabsContent value="regula-wejscia">
          <Outlet />
        </TabsContent> */}
      </Tabs>
    </div>
  );
};
