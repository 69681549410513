import { XCircle } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

type Props = {
  clearFilters: () => void;
};

export const OrderFilterClearButton = ({ clearFilters }: Props) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <button onClick={clearFilters}>
            <XCircle className="stroke-slate-700 hover:stroke-slate-900 hover:duration-300" />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Kliknij, aby wyczyścić filtry</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
