import { useDeleteTicketMutation, useGetTicketByIdQuery } from '@/api/tickets';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TicketDetails } from '@/types/ticketDetails';
import { ChevronLeft, Trash } from 'lucide-react';
import { ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { AddEditTicket } from '../ticket/AddEditTicket';
import { DataTable } from './DataTable';
import { TicketDescription } from './TicketDescription';
import { columns } from './columns';
import { PassesView } from '../pass/PassesView';

export const Ticket = (): ReactElement => {
  const [deleteTicket] = useDeleteTicketMutation();
  const { ticketId } = useParams();

  const { data, isLoading } = useGetTicketByIdQuery(
    { ticketId: ticketId! },
    { skip: !ticketId },
  );
  if (isLoading) return <span></span>;

  const ticket: TicketDetails = ({ ...data?.ticket, index: 1 } ??
    null) as TicketDetails;

  return (
    <div className="mt-4 mx-4 flex flex-col">
      <div className="flex items-center gap-x-2 text-xs leading-tight font-medium">
        <Link
          to={`/wydarzenia/${ticketId}/bilety`}
          className="flex gap-x-1 items-center pl-1.5 py-1.5 pr-2"
        >
          <ChevronLeft className="w-4 h-4" />
          Powrót
        </Link>
        <div>
          <span className="text-slate-600">Bilety</span> / {ticket.name}
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="mt-3 w-20 h-20 bg-slate-100 border border-slate-400 rounded-md" />
        <div className="flex justify-between items-center w-full">
          <p className="text-2xl font-semibold leading-loose ml-4.5">
            {ticket.name}
          </p>
          <div className="flex">
            {/* We have to handle it in a different way. Switch is treated as
                button and validation fails due to nested buttons */}
            {/* <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="px-2.5 w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center">
                      <p>Dostępność</p>
                      <Info className="w-3.5 h-3.5 mx-1 text-slate-400" />
                    </div>
                    <Switch className="w-11 h-6" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    Wyłączenie tej opcji uniemożliwi użytkownikom zakup biletu
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider> */}
            <div className="flex items-center pl-1.5 pr-2 py-1.5">
              <AddEditTicket type="update" />
            </div>
            <ConfirmationModal
              type="delete"
              redirect={`/wydarzenia/${ticket.event.id}/bilety`}
              title="Usuń bilet"
              description="Czy na pewno chcesz usunąć ten bilet?"
              onConfirm={() => deleteTicket({ ticketId })}
            >
              <div className="flex items-center pl-1.5 pr-2 py-1.5 text-red-500">
                <Trash className="w-4 h-4 mr-1" />
                Usuń
              </div>
            </ConfirmationModal>
          </div>
        </div>
      </div>
      {!!ticket && <DataTable columns={columns} data={[ticket]} />}
      <Tabs defaultValue="purchased" className="mt-6">
        <TabsList>
          <TabsTrigger value="purchased">Zakupione bilety</TabsTrigger>
          {/* <TabsTrigger value="description">Opis biletu</TabsTrigger> */}
        </TabsList>
        <TabsContent value="purchased">
          <PassesView />
        </TabsContent>
        <TabsContent value="description">
          <TicketDescription />
        </TabsContent>
      </Tabs>
    </div>
  );
};
