import { useUpdateUserMutation } from '@/api/users';
import { useCurrentUser } from '@/slices/credentials/selectors';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ChevronLeft, Trash } from 'lucide-react';
import { ChangeEvent, useState } from 'react';
import { createPortal } from 'react-dom';
import * as Yup from 'yup';
import { Button } from '../ui/button';
import { Toaster } from '../ui/toaster';
import { useToast } from '../ui/use-toast';

type Props = {
  setContent: (content: string) => void;
};

export const AccountDetails = ({ setContent }: Props) => {
  const { toast } = useToast();
  const user = useCurrentUser();
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const [updateUser] = useUpdateUserMutation();
  const Schema = Yup.object().shape({
    firstName: Yup.string().required('Imię jest wymagane'),
    lastName: Yup.string().required('Nazwisko jest wymagane'),
    avatarImage: Yup.string(),
  });

  const handleChangeAvatar = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      setAvatarImage(file);
    }
  };

  return (
    <div className="px-6 py-2 flex flex-col absolute z-10 lg:static bg-slate-50 lg:bg-white w-screen h-screen lg:w-[503px]">
      <button
        onClick={() => setContent('')}
        className="flex items-center gap-x-1 py-4 w-16 mb-6"
      >
        <ChevronLeft className="size-4 stroke-slate-700" />
        <p className="text-slate-700 text-sm font-medium">Wróć</p>
      </button>
      <h1 className="text-xl font-semibold leading-7 h-11">Dane konta</h1>
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          avatarImage: user?.avatarPath || '',
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          updateUser({ userId: user?.id ?? '', user: values });

          toast({
            title: 'Zapisano zmiany',
            description: 'Dane konta zostały zaktualizowane',
            duration: 2000,
          });
        }}
      >
        {({ isValid, setFieldValue }) => (
          <Form className="flex flex-col gap-y-1">
            <label
              htmlFor="firstName"
              className="text-sm font-medium leading-tight mt-6 mb-1.5"
            >
              Imię
            </label>
            <Field
              type="text"
              id="firstName"
              name="firstName"
              className="bg-white border rounded-lg px-4 py-2.5"
            />
            <ErrorMessage
              name="firstName"
              component="p"
              className="text-red-500 text-sm mt-1"
            />
            <label
              htmlFor="lastName"
              className="text-sm font-medium leading-tight mt-6 mb-1.5"
            >
              Nazwisko
            </label>
            <Field
              type="text"
              id="lastName"
              name="lastName"
              className="bg-white border rounded-lg px-4 py-2.5"
            />
            <ErrorMessage
              name="lastName"
              component="p"
              className="text-red-500 text-sm mt-1"
            />
            <label
              htmlFor="email"
              className="text-sm font-medium leading-tight mt-6 mb-1.5"
            >
              Adres e-mail
            </label>
            <Field
              type="text"
              id="email"
              name="email"
              disabled
              className="bg-white border rounded-lg px-4 py-2.5 select-all"
            />
            {/* <label className="text-sm font-medium leading-tight mt-6 mb-1.5">
              Awatar
            </label>
            <div>
              <label
                htmlFor="avatarImage"
                className="flex items-center justify-center w-full py-8 bg-slate-50 rounded border border-dashed border-slate-400 cursor-pointer"
              >
                <div className="flex flex-col justify-center items-center gap-y-4">
                  <Files className="w-16 h-16" />
                  <p className="text-center">
                    Przeciągnij i umieść plik tutaj, lub{' '}
                    <span className="font-medium underline underline-offset-4">
                      wybierz plik
                    </span>
                  </p>
                </div>
              </label>
              <Field
                id="avatarImage"
                name="avatarImage"
                type="file"
                accept="image/png, image/jpeg, image/svg+xml, image/webp, image/avif"
                className="sr-only"
                onChange={handleChangeAvatar}
              />
            </div> */}
            <p className="text-sm font-medium leading-tight mt-4 mb-2">
              {avatarImage && 'Wybrane zdjęcie:'}
            </p>
            {avatarImage && (
              <div className="relative group size-24">
                <img
                  src={URL.createObjectURL(avatarImage)}
                  alt="Uploaded image"
                  className="object-cover size-24 rounded"
                />
                <Button
                  className="hidden group-hover:flex justify-center items-center size-full absolute left-0 top-0 bg-transparent hover:bg-red-500/50 duration-200"
                  onClick={() => setAvatarImage(null)}
                  type="button"
                >
                  <Trash className="w-4 h-4" />
                </Button>
              </div>
            )}

            <ErrorMessage
              name="avatarImage"
              component="p"
              className="text-red-500 text-sm mt-1"
            />
            <div className="flex justify-end mt-10">
              <Button type="submit" disabled={!isValid} className="px-5 py-2.5">
                Zapisz zmiany
              </Button>
              {createPortal(<Toaster />, document.body)}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
