import { useDeleteDiscountMutation } from '@/api/discount';
import { Discount } from '@/types/discount';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { AddEditDiscount } from './AddEditDiscount';

export const columns: ColumnDef<Discount>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'description',
    header: 'Opis',
  },
  {
    accessorKey: 'minimumTickets',
    header: 'Minimalna ilość biletów',
  },
  {
    accessorKey: 'from',
    header: 'Od',
    cell: ({ row }) => {
      const date = new Date(row.original.from);
      const time = `${date.getHours()}:${date.getMinutes()}`;
      return date.toLocaleDateString() + ' ' + time;
    },
  },
  {
    accessorKey: 'to',
    header: 'Do',
    cell: ({ row }) => {
      const date = new Date(row.original.to);
      const time = `${date.getHours()}:${date.getMinutes()}`;
      return date.toLocaleDateString() + ' ' + time;
    },
  },
  {
    accessorKey: 'code',
    header: 'Kod',
  },
  {
    accessorKey: 'discount',
    header: 'Wartość promocji',
  },
  {
    accessorKey: 'type',
    header: 'Rodzaj promocji',
    cell: ({ row }) => {
      return row.original.type === 'PRICE' ? 'Cenowa' : 'Procentowa';
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const { eventId } = useParams();
      const [deleteDiscount] = useDeleteDiscountMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <AddEditDiscount type="update" discount={row.original} />
              <ConfirmationModal
                type="delete"
                title="Czy na pewno chcesz usunąć ten bilet?"
                description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
              Twojego biletu i usunięcie związanych z nim danych z naszych
              serwerów."
                onConfirm={() =>
                  deleteDiscount({
                    eventId: eventId!,
                    discountId: row.original.id,
                  })
                }
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
