import { Discount } from '@/types/discount';
import { Event } from '@/types/event';
import { api } from './api';

const baseUrl = '/discounts';

const discountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscounts: builder.query<
      { discounts: Discount[] },
      { eventId: Event['id'] }
    >({
      providesTags: ['Discount'],
      query: ({ eventId }) => `${baseUrl}/events/${eventId}`,
    }),
    createDiscount: builder.mutation<
      { discount: Discount },
      Partial<Discount> & { eventId: Event['id'] }
    >({
      query: (body) => ({
        url: `${baseUrl}/events/${body.eventId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Discount'],
    }),
    updateDiscount: builder.mutation<
      { discount: Discount },
      Partial<Discount> & {
        discountId: Discount['id'];
        eventId: Event['id'];
      }
    >({
      query: ({ discountId, eventId, ...body }) => ({
        url: `${baseUrl}/events/${eventId}/${discountId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Discount'],
    }),
    deleteDiscount: builder.mutation<
      void,
      { discountId: Discount['id']; eventId: Event['id'] }
    >({
      query: ({ discountId, eventId }) => ({
        url: `${baseUrl}/events/${eventId}/${discountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Discount'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = discountsApi.endpoints;
export const {
  useGetAllDiscountsQuery,
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
} = discountsApi;
