import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Discounts } from './components/discount/Discounts';
import { Delivery } from './components/eventMenu/Delivery';
import { EventMenu } from './components/eventMenu/EventMenu';
import { Mailing } from './components/eventMenu/Mailing';
import { TicketView } from './components/eventMenu/TicketView';
import { Event } from './components/eventView/Event';
import { Events } from './components/eventsView/Events';
import { Horeca } from './components/horeca/Horeca';
import { Login } from './components/login/Login';
import { OrderView } from './components/orderView/OrderView';
import { OrdersView } from './components/ordersView/OrdersView';
import { Ticket } from './components/ticketDetails/Ticket';
import { TicketEntryRule } from './components/ticketEntryRule/TicketEntryRule';
import { TicketGenerator } from './components/ticketGenerator/TicketGenerator';
import { TicketTags } from './components/ticketTags/TicketTags';

export const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    children: [
      {
        path: 'wydarzenia',
        element: <Events />,
      },
      {
        path: 'wydarzenia',
        element: <Event />,
        children: [
          {
            path: ':eventId',
            element: <EventMenu />,
            children: [
              {
                path: 'bilety',
                element: <TicketView />,
              },
              {
                path: 'generator-biletow',
                element: <TicketGenerator />,
              },
              {
                path: 'tagi-biletow',
                element: <TicketTags />,
              },
              {
                path: 'dostawa',
                element: <Delivery />,
              },
              {
                path: 'mailing',
                element: <Mailing />,
              },
              {
                path: 'promocje',
                element: <Discounts />,
              },
              {
                path: 'horeca',
                element: <Horeca />,
              },
              {
                path: 'regula-wejscia',
                element: <TicketEntryRule />,
              },
            ],
          },
          {
            path: ':eventId/bilety/:ticketId',
            element: <Ticket />,
          },
          {
            path: ':eventId/bilety/:ticketId/zakupione',
            element: <Ticket />,
          },
          {
            path: ':eventId/zamowienia',
            element: <OrdersView />,
          },
          {
            path: ':eventId/zamowienia/:orderId',
            element: <OrderView />,
          },
          {
            path: ':eventId/arts',
            element: <p>artykuły</p>,
          },
          {
            path: ':ieventIdd/statystyki',
            element: <p>statystyki</p>,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
