import { Event } from '@/types/event';
import { Order } from '@/types/order';
import { api } from './api';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersByEventId: builder.query<
      { orders: Order[]; count: number },
      {
        eventId?: Event['id'];
        skip?: number;
        take?: number;
        status?: string;
        email?: string;
        orderId?: string;
      }
    >({
      providesTags: ['Event'],
      query: ({ eventId, skip, take, status, email, orderId }) =>
        `/orders/events/${eventId}?skip=${skip}&take=${take}&status=${status}&email=${email}&orderId=${orderId}`,
    }),
    getOrderById: builder.query<{ order: Order }, { orderId?: Order['id'] }>({
      providesTags: ['Event'],
      query: ({ orderId }) => `/orders/${orderId}`,
    }),
  }),
  overrideExisting: false,
});

export const eventsApiEndpoints = ordersApi.endpoints;
export const { useGetOrdersByEventIdQuery, useGetOrderByIdQuery } = ordersApi;
