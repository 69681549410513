import { useDeleteEventMutation, useGetEventByIdQuery } from '@/api/events';
import { trimId } from '@/utils/trimId';
import clsx from 'clsx';
import { format } from 'date-fns';
import {
  Calendar,
  ChevronLeft,
  CircleDot,
  ShoppingCart,
  Ticket,
  Trash,
} from 'lucide-react';
import { ReactElement } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { AddEditEvent } from '../eventsView/AddEditEvent';
import { getEventAvailability } from '../eventsView/get-event-availability';
import { Button } from '../ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';

export const EventSummary = (): ReactElement => {
  const { eventId } = useParams();
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop();
  const { data } = useGetEventByIdQuery(
    { eventId: eventId ?? '' },
    { skip: !eventId },
  );
  const event = data?.event;
  const { statusClassName, statusLabel } = getEventAvailability(event);
  const [deleteEvent] = useDeleteEventMutation();

  return (
    <Card className="2xl:min-w-80.5 2xl:h-154 mx-0 md:mx-4 2xl:ml-4 2xl:mr-0 mt-4">
      <CardHeader className="px-4">
        <div className="flex justify-between py-1.5 mb-2">
          <Link to="/wydarzenia" className="flex items-center gap-x-1">
            <ChevronLeft className="size-4 stroke-slate-700" />
            <p className="text-slate-700 text-sm font-medium">Wydarzenia</p>
          </Link>
          <div className="flex w-36 sr-only 2xl:not-sr-only">
            <AddEditEvent type="update" event={event} />
            <ConfirmationModal
              type="delete"
              title="Czy na pewno chcesz usunąć to wydarzenie?"
              description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
                Twojego wydarzenia i usunięcie związanych z nim danych z naszych
                serwerów."
              onConfirm={() => deleteEvent({ eventId: event!.id! })}
            >
              <Button
                variant="ghost"
                className="px-2 w-full justify-start text-red-500 hover:text-red-500"
              >
                <Trash className="w-4 h-4 mr-1" />
                Usuń
              </Button>
            </ConfirmationModal>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-semibold leading-7">
            {event?.name}
          </CardTitle>
          <div className="flex w-36 2xl:sr-only">
            <AddEditEvent type="update" event={event} />
            <ConfirmationModal
              type="delete"
              title="Czy na pewno chcesz usunąć to wydarzenie?"
              description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
                Twojego wydarzenia i usunięcie związanych z nim danych z naszych
                serwerów."
              onConfirm={() => deleteEvent({ eventId: event!.id! })}
            >
              <Button
                variant="ghost"
                className="px-2 w-full justify-start text-red-500 hover:text-red-500"
              >
                <Trash className="w-4 h-4 mr-1" />
                Usuń
              </Button>
            </ConfirmationModal>
          </div>
        </div>
        <div className="text-slate-900 text-sm font-normal leading-tight">
          <ShortenStringClipboard
            content={event?.id ?? ''}
            argName="ID"
            trimCallback={trimId}
          />
        </div>
      </CardHeader>
      <CardContent className="flex flex-col md:grid md:grid-cols-3 2xl:flex 2xl:flex-col gap-y-4 px-4">
        <div>
          <p className="text-slate-500 text-xs font-normal mb-1">Status</p>
          <p
            className={`${statusClassName} inline-flex text-sm items-center gap-x-1 px-2 py-1 rounded-md border border-1 `}
          >
            <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
          </p>
        </div>
        <div>
          <p className="text-slate-500 text-xs font-normal mb-1">
            Data wydarzenia
          </p>
          <p className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {event && format(new Date(event.from), 'dd / MM / yyyy HH:MM')}
          </p>
          <p className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {event && format(new Date(event.to), 'dd / MM / yyyy HH:MM')}
          </p>
        </div>
        <div>
          <p className="text-slate-500 text-xs font-normal mb-1">
            Liczba biletów
          </p>
          <p className="text-sm font-normal leading-tight">
            {event?.totalAvailableTickets}
          </p>

          <div className="mt-2">
            <p className="text-slate-500 text-xs font-normal mb-1">
              Liczba sprzedanych biletów
            </p>
            <p className="text-sm font-normal leading-tight">
              {event?.totalSoldTickets}
            </p>
          </div>
        </div>
      </CardContent>
      <hr className="mb-4" />
      <CardFooter className="flex 2xl:flex-col mx-4 2xl:mx-0 px-0 pb-4 overflow-x-auto">
        <Button
          variant="ghost"
          asChild
          className={clsx(
            selectedTab === 'bilety' &&
              'bg-slate-200 border 2xl:border-l-0 2xl:border-r-0 border-t border-b border-slate-400',
            'justify-start hover:bg-slate-200 rounded-md 2xl:rounded-none',
          )}
        >
          <Link
            to={`/wydarzenia/${event?.id}/bilety`}
            className="flex items-center 2xl:w-full"
          >
            <Ticket className="w-4 h-4 mr-1" />
            Bilety
          </Link>
        </Button>
        <Button
          variant="ghost"
          asChild
          className={clsx(
            selectedTab === 'zamowienia' &&
              'bg-slate-200 border 2xl:border-l-0 2xl:border-r-0 border-t border-b border-slate-400',
            'justify-start hover:bg-slate-200 rounded-md 2xl:rounded-none',
          )}
        >
          <Link
            to={`/wydarzenia/${event?.id}/zamowienia`}
            className="flex items-center 2xl:w-full"
          >
            <ShoppingCart className="w-4 h-4 mr-1" />
            Zamówienia
          </Link>
        </Button>
        {/* <Button
          variant="ghost"
          asChild
          className={clsx(
            selectedTab === 'artykuly' &&
              'bg-slate-200 border-t border-b border-slate-400',
            'justify-start hover:bg-slate-200 rounded-none',
          )}        >
          <Link
            to={`/wydarzenia/${event?.id}/arts`}
            className="flex items-center 2xl:w-full"
          >
            <MessageSquare className="w-4 h-4 mr-1" />
            Artykuły
          </Link>
        </Button>
        <Button
          variant="ghost"
          asChild
          className={clsx(
            selectedTab === 'statystyki' &&
              'bg-slate-200 border-t border-b border-slate-400',
            'justify-start hover:bg-slate-200 rounded-none',
          )}        >
          <Link
            to={`/wydarzenia/${event?.id}/statystyki`}
            className="flex items-center 2xl:w-full"
          >
            <BarChart className="w-4 h-4 mr-1" />
            Statystyki
          </Link>
        </Button> */}
      </CardFooter>
    </Card>
  );
};
