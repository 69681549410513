import { User } from '@/types/user';
import { api } from './api';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<{ user: User }, void>({
      providesTags: ['User'],
      query: () => `/users/current`,
    }),
    getAllUsers: builder.query<{ users: User[] }, void>({
      providesTags: ['User'],
      query: () => '/users',
    }),
    deleteUser: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    createUser: builder.mutation<User, { user: User }>({
      query: ({ user }) => ({
        url: `/users`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<
      User,
      { userId: string; user: Pick<User, 'firstName' | 'lastName'> }
    >({
      query: ({ userId, user }) => ({
        url: `/users/${userId}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = usersApi.endpoints;
export const {
  useGetCurrentUserQuery,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
} = usersApi;
