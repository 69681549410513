import {
  useCreateDiscountMutation,
  useUpdateDiscountMutation,
} from '@/api/discount';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Discount } from '@/types/discount';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, File, Plus } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../ui/button';

import { useGetAllTicketTagsQuery } from '@/api/ticketTag';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';

const Schema = yup.object({
  name: yup.string().max(60, {
    message: 'Nazwa może zawierać maksymalnie 60 znaków',
  }),
  description: yup.string(),
  code: yup
    .string()
    .min(6, 'Promocode musi mieć co najmniej 6 znaków')
    .max(12, 'Promocode może mieć maksymalnie 12 znaków')
    .required('Promocode jest wymagany'),
  from: yup.object().shape({
    date: yup.string().required('Data rozpoczęcia jest wymagana'),
    time: yup.string(),
  }),
  to: yup.object().shape({
    date: yup.string().required('Data zakończenia jest wymagana'),
    time: yup.string(),
  }),
  discount: yup.number().required('Wartość promocji jest wymagana'),
  type: yup
    .string()
    .oneOf(['PRICE', 'PERCENT'])
    .required('Typ promocji jest wymagany'),
  minimumTickets: yup
    .number()
    .required('Minimalna liczba biletów jest wymagana'),
  // ticketTag: yup.string().required('Tag biletu jest wymagany'),
});

type Props = {
  light?: boolean;
  type?: 'update';
  discount?: Discount;
};

export const AddEditDiscount = ({
  light,
  type,
  discount,
}: Props): ReactElement => {
  const { eventId } = useParams();
  const [date, setDate] = useState<Date>();
  const [open, setOpen] = useState(false);
  const [createDiscount] = useCreateDiscountMutation();
  const [updateDiscount] = useUpdateDiscountMutation();
  const { data: ticketTags } = useGetAllTicketTagsQuery(
    {
      eventId: eventId!,
    },
    { skip: !event },
  );

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: discount ? discount.name : '',
      description: discount ? discount.description : '',
      code: discount ? discount.code : '',
      from: {
        date: discount ? format(new Date(discount.from), 'yyyy-MM-dd') : '',
        time: discount ? format(new Date(discount.from), 'HH:mm') : '',
      },
      to: {
        date: discount ? format(new Date(discount.to), 'yyyy-MM-dd') : '',
        time: discount ? format(new Date(discount.to), 'HH:mm') : '',
      },
      discount: discount ? discount.discount : 0,
      type: discount ? discount.type : 'PRICE',
      minimumTickets: discount ? discount.minimumTickets : 0,
      // ticketTag: discount ? discount.tag.type : '',
    },
  });

  const { watch } = form;
  const nameFieldValue = watch('name') || discount?.name || '';

  useEffect(() => {
    if (discount) {
      form.reset({
        ...discount,
        from: {
          date: format(new Date(discount.from), 'yyyy-MM-dd'),
          time: format(new Date(discount.from), 'HH:mm'),
        },
        to: {
          date: format(new Date(discount.to), 'yyyy-MM-dd'),
          time: format(new Date(discount.to), 'HH:mm'),
        },
      });
    }
  }, [discount, form.reset]);

  const getProperDate = (discountDate: string, time?: string) => {
    const [hours, minutes] = time?.split(':') ?? [0, 0];
    const date = new Date(discountDate);
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date.toISOString();
  };

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();

      if (!isValid) return;

      const formattedValues = {
        ...values,
        from: getProperDate(values.from.date, values.from.time),
        to: getProperDate(values.to.date, values.to.time),
      };

      if (type === 'update') {
        await updateDiscount({
          discountId: discount?.id!,
          eventId: eventId!,
          ...formattedValues,
        });
      } else {
        await createDiscount({
          eventId: eventId!,
          ...formattedValues,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj promocję</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-160 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose">
            {isUpdate ? 'Edytuj promocję' : 'Dodaj promocję'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <div className="flex items-center gap-x-4">
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription className="whitespace-nowrap">
                        {nameFieldValue.length} / 60 znaków
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Opis</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex gap-x-4">
                <FormField
                  control={form.control}
                  name="minimumTickets"
                  render={({ field }) => (
                    <FormItem className="w-64">
                      <FormLabel>Minimalna ilość biletów</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* <FormField
                  control={form.control}
                  name="ticketTag"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>Tag biletu</FormLabel>
                      <FormDescription>
                        <Select
                          onValueChange={(value) =>
                            form.setValue('ticketTag', value)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Wybierz tag" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {ticketTags?.tags
                                .filter(({ type }) => type === 'DISCOUNT')
                                .map((tag) => (
                                  <SelectItem key={tag.id} value={tag.id}>
                                    {tag.name}
                                  </SelectItem>
                                ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                /> */}
              </div>
              <FormField
                control={form.control}
                name="from"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Data rozpoczęcia</FormLabel>
                    <FormControl>
                      <div className="flex gap-x-4">
                        <FormField
                          control={form.control}
                          name="from.date"
                          render={({ field }) => (
                            <FormItem className="flex flex-col w-full">
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={'outline'}
                                      className={cn(
                                        'w-full justify-start text-left font-normal',
                                        !date && 'text-muted-foreground',
                                      )}
                                    >
                                      <CalendarIcon className="mr-2 h-4 w-4" />
                                      {field?.value ? (
                                        format(
                                          new Date(field.value),
                                          'dd / MM / yyyy',
                                        )
                                      ) : (
                                        <span>Wybierz datę</span>
                                      )}
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent
                                  className="w-auto p-0"
                                  align="start"
                                >
                                  <Calendar
                                    mode="single"
                                    selected={new Date(field.value)}
                                    onSelect={field.onChange}
                                    initialFocus
                                  />
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="from.time"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input {...field} type="time" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="to"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Data zakończenia</FormLabel>
                    <FormControl>
                      <div className="flex gap-x-4">
                        <FormField
                          control={form.control}
                          name="to.date"
                          render={({ field }) => (
                            <FormItem className="flex flex-col w-full">
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant={'outline'}
                                      className={cn(
                                        'w-full justify-start text-left font-normal',
                                        !date && 'text-muted-foreground',
                                      )}
                                    >
                                      <CalendarIcon className="mr-2 h-4 w-4" />

                                      {field?.value ? (
                                        format(
                                          new Date(field.value),
                                          'dd / MM / yyyy',
                                        )
                                      ) : (
                                        <span>Wybierz datę</span>
                                      )}
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent
                                  className="w-auto p-0"
                                  align="start"
                                >
                                  <Calendar
                                    mode="single"
                                    selected={new Date(field.value)}
                                    onSelect={field.onChange}
                                    initialFocus
                                  />
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="to.time"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input {...field} type="time" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-end gap-x-4">
                <FormField
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Kod</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="discount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Wartość promocji</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" className="w-28" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Select
                  onValueChange={(value: 'PRICE' | 'PERCENT') =>
                    form.setValue('type', value)
                  }
                  defaultValue={discount?.type}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Rodzaj promocji" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="PRICE">zł</SelectItem>
                    <SelectItem value="PERCENT">%</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz promocję
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
