import {
  useCreateTicketGeneratorMutation,
  useUpdateTicketGeneratorMutation,
} from '@/api/ticketGenerator';
import { useGetAllTicketTagsQuery } from '@/api/ticketTag';
import { TicketGenerator } from '@/types/ticketGenerator';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Info, Plus } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';

const Schema = yup.object({
  name: yup.string().max(60, {
    message: 'Nazwa może zawierać maksymalnie 60 znaków',
  }),
  range: yup.string(),
  amount: yup
    .number()
    .min(1, 'Wpisz co najmniej 1 bilet do stworzenia')
    .required('Wartość wymagana'),
  price: yup.number().required('Wartość wymagana'),
  ticketTag: yup.string().required('Tag biletu jest wymagany'),
});

type Props = {
  light?: boolean;
  type?: 'update';
  ticketGenerator?: TicketGenerator;
};

export const AddEditTicketGenerator = ({
  light,
  type,
  ticketGenerator,
}: Props): ReactElement => {
  const { eventId } = useParams();
  const [open, setOpen] = useState(false);
  const [createTicketGenerator] = useCreateTicketGeneratorMutation();
  const [updateTicketGenerator] = useUpdateTicketGeneratorMutation();

  const { data: ticketTags } = useGetAllTicketTagsQuery(
    {
      eventId: eventId!,
    },
    { skip: !event },
  );

  const isUpdate = type === 'update';

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: ticketGenerator ? ticketGenerator.name : '',
      range: ticketGenerator ? ticketGenerator.range : '',
      amount: ticketGenerator ? ticketGenerator.amount : 0,
      price: ticketGenerator ? ticketGenerator.price : 0,
      ticketTag: ticketGenerator ? ticketGenerator.tag.type : '',
    },
  });

  useEffect(() => {
    if (ticketGenerator) {
      form.reset(ticketGenerator);
    }
  }, [ticketGenerator, form.reset]);

  const { watch } = form;
  const nameFieldValue = watch('name') || ticketGenerator?.name || '';

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();

      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updateTicketGenerator({
          ticketGeneratorId: ticketGenerator?.id!,
          body: values,
        });
      } else {
        await createTicketGenerator({
          ...values,
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Generuj bilet</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-160 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose">
            {isUpdate ? 'Edytuj bilet' : 'Generuj bilet'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <div className="flex items-center gap-x-4">
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription className="whitespace-nowrap">
                        {nameFieldValue.length} / 60 znaków
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <hr className="my-4" />
              <p className="text-sm font-medium leading-tight mb-2">Wstęp</p>
              <p className="text-slate-500 text-xs font-normal leading-none flex items-center gap-x-1 mb-4">
                <Info className="w-3.5 h-3.5" />
                Data wydarzenia:
                <span className="text-slate-900 font-medium">
                  7 - 8 czerwiec 2024
                </span>
              </p>
              <div className="flex justify-between">
                <div className="flex flex-col justify-around">
                  <p className="text-slate-700 text-sm font-normal leading-tight">
                    Jednodniowy
                  </p>
                  <p className="text-slate-700 text-sm font-normal leading-tight">
                    Dwudniowy
                  </p>
                </div>
                <Tabs
                  defaultValue={
                    !!ticketGenerator ? ticketGenerator.range : 'first_day'
                  }
                >
                  <TabsList className="flex flex-col h-full">
                    <div>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'first_day')}
                        value="first_day"
                      >
                        I dzień
                      </TabsTrigger>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'second_day')}
                        value="second_day"
                      >
                        II dzień
                      </TabsTrigger>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'any')}
                        value="any"
                      >
                        Dowolne
                      </TabsTrigger>
                    </div>
                    <TabsTrigger
                      onClick={() => form.setValue('range', 'full')}
                      value="full"
                    >
                      I dzień - II dzień
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <FormField
                  control={form.control}
                  name="ticketTag"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tag biletu</FormLabel>
                      <FormDescription className="whitespace-nowrap w-44">
                        <Select
                          onValueChange={(newValue) =>
                            form.setValue('ticketTag', newValue)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Wybierz tag" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {ticketTags?.tags
                                .filter(
                                  ({ type }) => type === 'TICKET_ENTRY_RULE',
                                )
                                .map((tag) => (
                                  <SelectItem key={tag.id} value={tag.id}>
                                    {tag.name}
                                  </SelectItem>
                                ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cena</FormLabel>
                      <div className="flex gap-x-2 w-full">
                        <FormControl className="w-20">
                          <Input {...field} />
                        </FormControl>
                        <FormDescription className="whitespace-nowrap w-40">
                          <Select>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a currency" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup defaultValue="pln">
                                <SelectItem value="pln">PLN</SelectItem>
                                <SelectItem value="usd">USD</SelectItem>
                                <SelectItem value="eur">EUR</SelectItem>
                                <SelectItem value="gbp">GBP</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormDescription>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem className="w-28">
                    <FormLabel>Ilość</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Generuj
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
