import { ReactElement } from 'react';
import { OrderAccordion } from './OrderAccordion';
import { ProductList } from './ProductList';
import { useParams } from 'react-router-dom';
import { useGetOrderByIdQuery } from '@/api/orders';
import { Loading } from '../login/Loading';

export const OrderView = (): ReactElement => {
  const { orderId } = useParams();

  const { data, isLoading } = useGetOrderByIdQuery(
    { orderId },
    { skip: !orderId },
  );

  if (isLoading) return <Loading />;

  const order = data?.order;

  return (
    <div className="flex flex-col w-full">
      <h1 className="text-slate-500 text-2xl font-semibold leading-loose ml-10 mt-12 mb-6">
        Zamówienie <span className="text-slate-900">#001</span>
      </h1>
      <div className="flex gap-x-10 px-10">
        <OrderAccordion order={order!} />
        <ProductList order={order!} />
      </div>
    </div>
  );
};
