import calendar from '@/assets/calendar.svg';
import { MenuListType } from '@/types/menuListType';

export const menuList: MenuListType[] = [
  // {
  //   name: 'Dashboard',
  //   href: '/',
  //   icon: home,
  // },
  {
    name: 'Wydarzenia',
    href: '/wydarzenia',
    icon: calendar,
  },
];
