import { menuList } from '@/constants/menuList';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/button';

export const MenuButtons = (): ReactElement => {
  const location = useLocation();
  return (
    <div className="flex items-center">
      {menuList.map(({ name, href, icon }) => {
        return (
          <Link key={name} to={href} className="text">
            <Button
              key={name}
              variant="ghost"
              className={`${
                location.pathname === href ? 'bg-slate-200' : ''
              } hover:bg-slate-200 px-5 py-2.5`}
            >
              <div className="flex gap-x-1 items-center w-full">
                <img src={icon} alt={`${name} icon`} />
                <p className="text-sm font-medium leading-tight">{name}</p>
              </div>
            </Button>
          </Link>
        );
      })}
    </div>
  );
};
