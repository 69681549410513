import { useSendTicketsToClientMutation } from '@/api/tickets';
import { Order } from '@/types/order';
import { OrderItem } from '@/types/orderItem';
import { File, Mail, MapPin } from 'lucide-react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal';
import { Button } from '../ui/button';

export const ProductList = ({ order }: { order: Order }): ReactElement => {
  const [sendTickets] = useSendTicketsToClientMutation();
  const nameMap: Record<string, string> = {
    full: 'dwudniowy',
    first_day: 'Jednodniowy (I dzień)',
    second_day: 'Jednodniowy (II dzień)',
    any: 'jednodniowy',
  };

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex justify-between w-full items-center mb-4">
        <p className="text-slate-700 text-xl font-medium leading-7">
          Lista produktów
        </p>
        {order?.ticketsFile && (
          <div className="flex flex-col gap-3">
            <ConfirmationModal
              title="Czy na pewno chcesz wysłać email z biletami do wydarzenia?"
              description="Ta operacja nie może być cofnięta."
              onConfirm={() => sendTickets({ orderId: order.id })}
            >
              <Button variant="ghost" className="px-2 w-full justify-start">
                <Mail className="w-4 h-4 mr-1" />
                Wyślij
              </Button>
            </ConfirmationModal>
            <Button asChild variant="secondary">
              <Link
                target="_blank"
                to={`https://storage.googleapis.com/dramoff-tickets/${order.ticketsFile}`}
              >
                <File className="w-6 h-6 mr-1" />
                <p className="text-sm font-medium leading-normal">
                  Zobacz dokument PDF
                </p>
              </Link>
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-y-4 h-96 overflow-y-auto">
        {order.orderItems.map(
          ({ id, ticket, amount, unitPrice }: OrderItem) => (
            <div key={id} className="grid grid-cols-6 items-center pr-11">
              <div className="col-span-2 flex gap-x-6 items-center">
                {ticket?.image ? (
                  <img src={ticket.image} />
                ) : (
                  <div className="w-16 h-16 bg-slate-200 rounded-md" />
                )}
                <div>
                  <p className="text-sm font-semibold leading-normal">
                    {ticket.name} - {nameMap[ticket.range]}
                  </p>
                  <p className="text-sm font-normal leading-normal">
                    {ticket.type === 'digital' ? 'Cyfrowy' : 'Fizyczny'}
                  </p>
                </div>
              </div>
              <div className="col-span-2 w-44">
                {ticket.type === 'physical' ? (
                  <div className="bg-slate-200 rounded-3xl px-3 py-1 flex items-center">
                    <MapPin className="w-4 h-4 mr-1" />
                    <p>Dostawa na adres</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <p className="text-end text-sm font-normal leading-tight">
                {amount} szt.
              </p>
              <p className="text-end text-sm font-normal leading-tight">
                {unitPrice.toFixed(2)}{' '}
                <span className="text-slate-500 text-xs font-normal leading-tight">
                  PLN
                </span>
              </p>
            </div>
          ),
        )}
      </div>
      <div className="grid grid-cols-6 items-center rounded-md bg-slate-100 w-full h-36">
        <p className="col-span-2 col-start-4 text-sm font-normal leading-normal">
          Kwota
        </p>
        <p className="col-span-1 text-center text-sm font-normal leading-tight">
          {order.totalTicketsPrice + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
        <p className="col-span-2 col-start-4 text-sm font-normal leading-normal">
          Dostawa
        </p>
        <p className="col-span-1 text-center text-sm font-normal leading-tight">
          {order.deliveryPrice + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
        <p className="col-span-2 col-start-4 text-sm font-semibold leading-normal">
          Suma
        </p>
        <p className="col-span-1 text-center text-xl font-medium leading-tight">
          {order.totalPrice + ' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
      </div>
    </div>
  );
};
