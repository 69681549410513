import { useCurrentUser } from '@/slices/credentials/selectors';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { Avatar, AvatarImage } from '../ui/avatar';

type Props = {
  menu?: boolean;
};

export const AvatarElement = ({ menu }: Props): ReactElement => {
  const user = useCurrentUser()!;
  return (
    <div
      className={clsx(
        'flex gap-y-1 items-center gap-x-2',
        menu ? 'flex-col sm:flex-row' : 'flex-row',
      )}
    >
      <Avatar className="size-8">
        <AvatarImage src="https://github.com/vercel.png" />
      </Avatar>
      {menu && (
        <p className="text-slate-700 text-xs font-medium leading-tight sm:sr-only">
          Konto
        </p>
      )}
      {user && (
        <div
          className={clsx(
            'flex flex-col text-start text-sm leading-tight',
            menu ? 'sr-only sm:not-sr-only' : '',
          )}
        >
          <h2 className="font-medium">{`${user.firstName} ${user.lastName}`}</h2>
        </div>
      )}
    </div>
  );
};
