import { Ticket } from '@/types/ticket';
import { api } from './api';
import { Pass } from '@/types/pass';

const passesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPasses: builder.query<{ passes: Pass[] }, { ticketId: Ticket['id'] }>(
      {
        providesTags: ['Pass'],
        query: ({ ticketId }) => `/passes/tickets/${ticketId}`,
      },
    ),
    togglePassActivity: builder.mutation<
      { pass: Pass },
      { passId: Pass['id'] }
    >({
      invalidatesTags: ['Pass'],
      query: ({ passId }) => ({
        url: `/passes/${passId}/toggle-activity`,
        method: 'PATCH',
      }),
    }),
  }),
  overrideExisting: false,
});

export const eventsApiEndpoints = passesApi.endpoints;
export const { useGetAllPassesQuery, useTogglePassActivityMutation } =
  passesApi;
