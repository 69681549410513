import { Horeca } from '@/types/horeca';
import { api } from './api';
import { Event } from '@/types/event';

const baseUrl = '/horeca-form-messages/events';

const horecaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllHoreca: builder.query<
      { horecaFormMessages: Horeca[] },
      { eventId: Event['id'] }
    >({
      providesTags: ['Horeca'],
      query: ({ eventId }) => `${baseUrl}/${eventId}`,
    }),
    deleteHoreca: builder.mutation<
      void,
      { horecaId: Horeca['id']; eventId: Event['id'] }
    >({
      query: ({ horecaId, eventId }) => ({
        url: `${baseUrl}/${eventId}/${horecaId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Horeca'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = horecaApi.endpoints;
export const { useGetAllHorecaQuery, useDeleteHorecaMutation } = horecaApi;
