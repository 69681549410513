import { useDeleteUserMutation, useGetAllUsersQuery } from '@/api/users';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ChevronLeft, MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../ConfirmationModal';
import { Avatar, AvatarImage } from '../ui/avatar';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { AddEditUser } from './AddEditUser';

type Props = {
  setContent: (content: string) => void;
};

export const Users = ({ setContent }: Props) => {
  const [deleteUser] = useDeleteUserMutation();
  const { data } = useGetAllUsersQuery();
  const users = data?.users ?? [];

  return (
    <div className="px-6 py-2 flex flex-col gap-y-6 absolute z-10 lg:static bg-slate-50 lg:bg-white w-screen h-screen lg:w-auto 2xl:w-[960px]">
      <button
        onClick={() => setContent('')}
        className="flex items-center gap-x-1 py-4 w-16"
      >
        <ChevronLeft className="size-4 stroke-slate-700" />
        <p className="text-slate-700 text-sm font-medium">Wróć</p>
      </button>
      <div className="flex justify-between items-center h-11">
        <h1 className="text-xl font-semibold leading-7">Użytkownicy</h1>
        <AddEditUser />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Nazwa</TableHead>
            <TableHead>Adres e-mail</TableHead>
            <TableHead>Rola</TableHead>
            <TableHead className="text-right">Akcje</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id} className="whitespace-nowrap">
              <TableCell className="flex items-center gap-x-2">
                {user.avatarPath ? (
                  <Avatar className="size-8">
                    <AvatarImage src={user.avatarPath} />
                  </Avatar>
                ) : (
                  <div className="flex justify-center items-center size-8 rounded-full bg-slate-200 border border-slate-300 text-[12.80px] leading-snug">
                    {user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
                  </div>
                )}
                {`${user.firstName} ${user.lastName}`}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell className="first-letter:uppercase">
                {user.role}
              </TableCell>
              <TableCell className="text-right pr-1.5">
                <div className="text-right">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="size-8 p-0 mr-2">
                        <span className="sr-only">Open menu</span>
                        <MoreHorizontal className="size-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        disabled
                        className="text-slate-500 text-xs font-semibold leading-none"
                      >
                        Opcje
                      </DropdownMenuItem>
                      <AddEditUser type="update" user={user} />
                      <ConfirmationModal
                        title="Usuń użytkownika"
                        description="Czy na pewno chcesz usunąć tego użytkownika?"
                        onConfirm={() => deleteUser({ userId: user.id })}
                      >
                        <Button
                          variant="ghost"
                          className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                        >
                          <Trash className="w-4 h-4 mr-1" />
                          Usuń
                        </Button>
                      </ConfirmationModal>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
