import { useGetEventByIdQuery } from '@/api/events';
import { ReactElement } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { EventSummary } from './EventSummary';

export const Event = (): ReactElement => {
  const { eventId } = useParams();
  useGetEventByIdQuery({ eventId: eventId ?? '' }, { skip: !eventId });
  return (
    <div className="flex flex-col 2xl:flex-row">
      <EventSummary />
      <Outlet />
    </div>
  );
};
