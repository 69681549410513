import { Table } from '@tanstack/react-table';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

interface DataTablePaginationProps<TData> {
  count: number;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

export function DataTablePagination<TData>({
  count,
  page,
  pageSize,
  setPage,
  setPageSize,
}: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center justify-end px-2 mx-4 mt-4 2xl:mx-10">
      {/* <div className="flex-1 text-sm text-muted-foreground sr-only">
        {table.getFilteredSelectedRowModel().rows.length} of{' '}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div> */}
      <div className="flex flex-col sm:flex-row items-center space-x-6 gap-2 md:gap-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Ilość wierszy na stronę</p>
          <Select
            value={String(pageSize)}
            onValueChange={(newValue: string) => setPageSize(Number(newValue))}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="sr-only sm:not-sr-only flex w-[100px] items-center justify-center text-sm font-medium">
          Strona {page} z {Math.ceil(count / pageSize)}
        </div>
        <div className="flex items-center gap-x-2 pb-4">
          <Button
            variant="outline"
            className="pl-1.5 pr-2 py-1 flex items-center gap-x-1"
            onClick={() => setPage(page - 1)}
            disabled={page - 1 === 0}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeft className="size-4 stroke-slate-700" />
            <p className="text-slate-700 text-sm font-medium">Poprzednia</p>
          </Button>
          <Button
            variant="outline"
            className="pl-1.5 pr-2 py-1 flex items-center gap-x-1"
            onClick={() => setPage(page + 1)}
            disabled={page === Math.ceil(count / pageSize)}
          >
            <span className="sr-only">Go to next page</span>
            <p className="text-slate-700 text-sm font-medium">Następna</p>
            <ChevronRight className="size-4 stroke-slate-700" />
          </Button>
        </div>
      </div>
    </div>
  );
}
