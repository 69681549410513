import { Event } from '@/types/event';
import { TicketTag } from '@/types/ticketTag';
import { api } from './api';

const baseUrl = '/tags/events';

const ticketTagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTicketTags: builder.query<
      { tags: TicketTag[] },
      { eventId: Event['id'] }
    >({
      providesTags: ['TicketTag'],
      query: ({ eventId }) => `${baseUrl}/${eventId}`,
    }),
    createTicketTag: builder.mutation<
      { tag: TicketTag },
      { name: string; eventId: Event['id']; type: TicketTag['type'] }
    >({
      query: ({ name, eventId, type }) => ({
        url: `${baseUrl}/${eventId}`,
        method: 'POST',
        body: { name, type },
      }),
      invalidatesTags: ['TicketTag'],
    }),
    updateTicketTag: builder.mutation<
      { tag: TicketTag },
      { ticketTagId: TicketTag['id']; name: string; eventId: Event['id'] }
    >({
      query: ({ ticketTagId, name, eventId }) => ({
        url: `${baseUrl}/${eventId}/${ticketTagId}`,
        method: 'PATCH',
        body: { name },
      }),
      invalidatesTags: ['TicketTag'],
    }),
    deleteTicketTag: builder.mutation<
      void,
      { ticketTagId: TicketTag['id']; eventId: Event['id'] }
    >({
      query: ({ ticketTagId, eventId }) => ({
        url: `${baseUrl}/${eventId}/${ticketTagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketTag'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = ticketTagsApi.endpoints;
export const {
  useGetAllTicketTagsQuery,
  useCreateTicketTagMutation,
  useUpdateTicketTagMutation,
  useDeleteTicketTagMutation,
} = ticketTagsApi;
