import { Event } from '@/types/event';
import { User } from '@/types/user';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CredentialsStore } from './credentialsSlice';
import { EventsStore } from './eventSlice';

type RootState = {
  credentials: CredentialsStore;
  events: EventsStore;
};

const selectUser = (state: RootState) => state.credentials.user;
const selectToken = (state: RootState) => state.credentials.accessToken;
const selectIsUserLoggedIn = createSelector(
  [selectToken],
  (accessToken) => !!accessToken,
);

export const useCurrentUser: () => User | undefined = () =>
  useSelector(selectUser)!;
export const useIsUserLoggedIn: () => boolean = () =>
  useSelector(selectIsUserLoggedIn);

export const useSelectedEvent: () => Event | undefined = () =>
  useSelector((state: RootState) => state.events.selectedEvent);
