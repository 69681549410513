import { EventStatus, Event } from '@/types/event';

export const getEventAvailability = (evnet?: Event) => {
  let statusClassName = '';
  let statusLabel = '';

  if (!evnet) {
    return { statusClassName, statusLabel };
  }

  switch (evnet.status) {
    case EventStatus.PAST:
      statusClassName = 'border-neutral-400 text-neutral-500';
      statusLabel = 'Zakończone';
      break;
    case EventStatus.SOON:
      statusClassName = 'border-cyan-600 text-cyan-600';
      statusLabel = 'Dopiero się wydarzy';
      break;
    case EventStatus.ONGOING:
      statusClassName = 'border-emerald-600 text-emerald-600';
      statusLabel = 'Właśnie trwa';
      break;
    case EventStatus.UNAVAILABLE:
      statusClassName = 'border-neutral-400 text-neutral-500';
      statusLabel = 'Niedostępne';
      break;
  }

  return { statusClassName, statusLabel };
};
