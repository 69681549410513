import logo from '@/assets/logo.webp';
import spinner from '@/assets/spinner.svg';
import { ReactElement } from 'react';

export const Loading = (): ReactElement => {
  return (
    <section className="flex justify-center items-center h-screen w-screen bg-slate-50">
      <div className="flex flex-col">
        <img src={logo} className="mx-auto w-64 mb-4" />
        <div className="flex gap-x-2 text-slate-600 justify-center items-center mt-4">
          <img src={spinner} className="animate-spin" />
          <p>Ładowanie aplikacji...</p>
        </div>
      </div>
    </section>
  );
};
