import { SettingsType, useGetSettingsByTypeQuery } from '@/api/settings';
import { SettingForm } from './SettingForm';

export const Mailing = () => {
  const { data } = useGetSettingsByTypeQuery({ type: SettingsType.MAILING });

  return (
    <div>
      {data?.settings.map((setting) => (
        <SettingForm key={setting.id} item={setting} />
      ))}
    </div>
  );
};
