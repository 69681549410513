import { useGetAllPassesQuery } from '@/api/pass';
import { NotFound } from '../NotFound';
import { DataTable } from './DataTable';
import { columns } from './columns';
import { useParams } from 'react-router-dom';

export const PassesView = () => {
  const { ticketId } = useParams();

  const { data } = useGetAllPassesQuery(
    { ticketId: ticketId! },
    { skip: !ticketId },
  );
  const entries = (data?.passes ?? []).map((pass, i) => ({
    ...pass,
    index: i + 1,
  }));

  return !!entries?.length ? (
    <DataTable columns={columns} data={entries} />
  ) : (
    <div className="mt-12">
      <NotFound
        description="Bilet nie został jeszcze zakupiony przez nikogo"
        element={
          <p className="text-sm font-medium leading-7">
            Kiedy bilet zostanie zakupiony, pojawi się w tym miejscu
          </p>
        }
      />
    </div>
  );
};
