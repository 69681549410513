import { useGetAllDiscountsQuery } from '@/api/discount';
import { useSelectedEvent } from '@/slices/credentials/selectors';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { DataTable } from '../eventsView/DataTable';
import { Loading } from '../login/Loading';
import { AddEditDiscount } from './AddEditDiscount';
import { columns } from './columns';

export const Discounts = (): ReactElement => {
  const event = useSelectedEvent();
  const { data, isLoading } = useGetAllDiscountsQuery(
    {
      eventId: event?.id ?? '',
    },
    { skip: !event },
  );
  const discounts = data?.discounts?.length;

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          Promocje
        </h1>
        {!!discounts && <AddEditDiscount light />}
      </div>
      {discounts ? (
        <DataTable columns={columns} data={data?.discounts ?? []} />
      ) : (
        <NotFound
          description="Nie znaleziono żadnej promocji"
          element={<AddEditDiscount />}
        />
      )}
    </div>
  );
};
