import { CredentialsStore } from '@/slices/credentials/credentialsSlice';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
    prepareHeaders(headers, { getState }) {
      const {
        credentials: { accessToken },
      } = getState() as { credentials: CredentialsStore };

      if (accessToken) headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
  keepUnusedDataFor: 30,
  tagTypes: [
    'User',
    'Event',
    'Ticket',
    'Setting',
    'TicketTag',
    'Discount',
    'Horeca',
    'TicketGenerator',
    'TicketEntryRule',
    'Pass',
  ],
});
