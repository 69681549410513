import {
  useDeleteTicketGeneratorMutation,
  useUpdateTicketGeneratorMutation,
} from '@/api/ticketGenerator';
import { TicketGenerator } from '@/types/ticketGenerator';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { MoreHorizontal, Trash } from 'lucide-react';
import { ConfirmationModal } from '../ConfirmationModal';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { AddEditTicketGenerator } from './AddEditTicketGenerator';

export const columns: ColumnDef<TicketGenerator>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: 'Nazwa',
  },
  {
    accessorKey: 'range',
    header: 'Wstęp',
    cell: ({ row }) => {
      let wstepLabel = '';

      switch (row.original.range) {
        case 'full':
          wstepLabel = 'Pełny';
          break;
        case 'first_day':
          wstepLabel = 'Jednodniowy (I dzień)';
          break;
        case 'second_day':
          wstepLabel = 'Jednodniowy (II dzień)';
          break;
        case 'any':
          wstepLabel = 'Dowolny';
          break;
      }

      return <p className="text-sm font-normal leading-tight">{wstepLabel}</p>;
    },
  },
  {
    accessorKey: 'amount',
    header: 'Ilość biletów',
  },
  {
    accessorKey: 'price',
    header: 'Cena biletu',
    cell: ({ row }) => {
      const formattedPrice = row.original.price.toFixed(2);

      return (
        <p className="text-sm font-normal leading-tight">
          {formattedPrice}{' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
      );
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      const [updateTicketGenerator] = useUpdateTicketGeneratorMutation();
      const [deleteTicketGenerator] = useDeleteTicketGeneratorMutation();

      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Opcje
              </DropdownMenuItem>
              <AddEditTicketGenerator
                type="update"
                ticketGenerator={row.original}
              />
              <ConfirmationModal
                type="delete"
                title="Czy na pewno chcesz usunąć ten bilet?"
                description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
              Twojego biletu i usunięcie związanych z nim danych z naszych
              serwerów."
                onConfirm={() =>
                  deleteTicketGenerator({ ticketGeneratorId: row.original.id })
                }
              >
                <Button
                  variant="ghost"
                  className="px-2 w-full justify-start text-red-500 hover:text-red-500"
                >
                  <Trash className="w-4 h-4 mr-1" />
                  Usuń
                </Button>
              </ConfirmationModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
