import { useGetEventByIdQuery } from '@/api/events';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
// import { useGetAllTicketEntryRuleQuery } from '@/api/ticketEntryRule';
// import { NotFound } from '../NotFound';
// import { DataTable } from '../eventsView/DataTable';
// import { Loading } from '../login/Loading';
// import { AddEditEntryRule } from './AddEditEntryRule';
// import { columns } from './columns';

export const TicketEntryRule = (): ReactElement => {
  const params = useParams();
  const eventId = params['eventId'];

  const { data: eventData } = useGetEventByIdQuery(
    { eventId },
    { skip: !eventId },
  );

  // const { data, isLoading } = useGetAllTicketEntryRuleQuery(
  //   { eventId },
  //   { skip: !eventId },
  // );

  // const ticketEntryRule = data?.ticketEntryRule?.length;

  // if (isLoading) return <Loading />;

  return (
    <p></p>
    // <div className="flex flex-col w-full">
    //   <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
    //     <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">Reguła wejścia</h1>
    //     {!!ticketEntryRule && <AddEditEntryRule light />}
    //   </div>
    //   {ticketEntryRule ? (
    //     !!eventData?.event && (
    //       <DataTable
    //         columns={columns(eventData?.event)}
    //         data={data?.ticketEntryRule ?? []}
    //       />
    //     )
    //   ) : (
    //     <NotFound
    //       description="Nie znaleziono żadnych wygenerowanych biletów"
    //       element={<TicketEntryRule />}
    //     />
    //   )}
    // </div>
  );
};
