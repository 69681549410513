import { useGetAllTicketTagsQuery } from '@/api/ticketTag';
import {
  useCreateTicketMutation,
  useGetTicketByIdQuery,
  useUpdateTicketMutation,
} from '@/api/tickets';
import { Ticket } from '@/types/ticket';
import { yupResolver } from '@hookform/resolvers/yup';
import { File, Info, Plus } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import MultipleSelector, { Option } from '../ui/multiple-selector';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';

const Schema = yup.object({
  name: yup.string().max(60, {
    message: 'Nazwa może zawierać maksymalnie 60 znaków',
  }),
  type: yup.string().default('digital'),
  range: yup.string(),
  amount: yup.string().test({
    name: 'is-positive',
    exclusive: true,
    message: 'Wpisz co najmniej 1 bilet do stworzenia',
    test: function (value) {
      const amount = Number(value);
      return amount > 0;
    },
  }),
  ticketTags: yup.array().of(yup.string()),
  price: yup.string(),
  generatable: yup.boolean(),
  source: yup.string(),
});

type Props = {
  light?: boolean;
  type?: 'update';
  ticket?: Ticket;
};

export const AddEditTicket = ({ light, type, ticket }: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [ticketsTags, setTicketsTags] = useState<Option[]>([]);
  const [generatable, setGeneratable] = useState(
    ticket ? ticket.generatable : false,
  );
  const { eventId } = useParams();
  const [createTicket] = useCreateTicketMutation();
  const [updateTicket] = useUpdateTicketMutation();

  const isUpdate = type === 'update';

  if (isUpdate && !ticket) {
    const { ticketId } = useParams();
    const { data } = useGetTicketByIdQuery(
      { ticketId: ticketId! },
      { skip: !ticketId },
    );
    ticket = data?.ticket;
  }
  const { data: ticketTags } = useGetAllTicketTagsQuery(
    {
      eventId: eventId!,
    },
    { skip: !event },
  );

  const form = useForm<yup.InferType<typeof Schema>>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: ticket ? ticket.name : '',
      type: ticket ? ticket.type : 'digital',
      range: ticket ? ticket.range : '',
      amount: ticket ? String(ticket.amount) : '',
      source: ticket ? ticket.source : '',
      price: ticket ? ticket.price.toFixed(2) : '',
      generatable: ticket ? ticket?.generatable : false,
      ticketTags: ticket ? ticket?.tags?.map((tag) => tag?.type) : [],
    },
  });

  const { watch } = form;
  const nameFieldValue = watch('name') || ticket?.name || '';

  useEffect(() => {
    if (ticket) {
      form.reset({
        ...ticket,
        amount: String(ticket.amount),
        price: String(ticket.price),
      } as unknown as yup.InferType<typeof Schema>);
    }
  }, [ticket, form.reset]);

  const onSubmit = async (values: yup.InferType<typeof Schema>) => {
    try {
      const isValid = await form.trigger();

      if (!isValid) {
        return;
      }

      if (type === 'update') {
        await updateTicket({
          id: ticket?.id!,
          ...values,
          generatable,
          amount: Number(values.amount),
          price: parseFloat(values.price as string),
        } as Ticket);
      } else {
        await createTicket({
          ...values,
          generatable,
          eventId: eventId as string,
          amount: Number(values.amount),
          price: parseFloat(values.price as string),
          tagsIds: ticketsTags.map(({ value }) => value),
        } as Partial<Ticket>);
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {isUpdate ? (
          <Button variant="ghost" className="px-2 w-full justify-start">
            <File className="w-4 h-4 mr-1" />
            Edytuj
          </Button>
        ) : (
          <Button
            className={
              light
                ? 'bg-slate-100 hover:bg-slate-200 border border-slate-300 text-slate-900'
                : ''
            }
          >
            <Plus className={`h-5 w-5 mr-2 text-slate-500`} />
            <p className="text-sm font-medium leading-normal">Dodaj bilet</p>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-160 p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold leading-loose">
            {isUpdate ? 'Edytuj bilet' : 'Dodaj bilet'}
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nazwa</FormLabel>
                    <div className="flex items-center gap-x-4">
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormDescription className="whitespace-nowrap">
                        {nameFieldValue.length} / 60 znaków
                      </FormDescription>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Tabs defaultValue={!!ticket ? ticket.type : 'digital'}>
                <TabsList>
                  <TabsTrigger
                    onClick={() => form.setValue('type', 'digital')}
                    value="digital"
                  >
                    Cyfrowy
                  </TabsTrigger>
                  <TabsTrigger
                    onClick={() => form.setValue('type', 'physical')}
                    value="physical"
                  >
                    Fizyczny
                  </TabsTrigger>
                </TabsList>
              </Tabs>
              {/* <div>
                <p>Generowalny</p>
                <Switch
                  name="generatable"
                  className="w-11 h-6"
                  defaultChecked={generatable}
                  onCheckedChange={() => {
                    setGeneratable(!generatable);
                  }}
                />
              </div> */}
              <hr className="my-4" />
              <p className="text-sm font-medium leading-tight mb-2">Wstęp</p>
              <p className="text-slate-500 text-xs font-normal leading-none flex items-center gap-x-1 mb-4">
                <Info className="w-3.5 h-3.5" />
                Data wydarzenia:
                <span className="text-slate-900 font-medium">
                  7 - 8 czerwiec 2024
                </span>
              </p>
              <div className="flex justify-between">
                <div className="flex flex-col justify-around">
                  <p className="text-slate-700 text-sm font-normal leading-tight">
                    Jednodniowy
                  </p>
                  <p className="text-slate-700 text-sm font-normal leading-tight">
                    Dwudniowy
                  </p>
                </div>
                <Tabs defaultValue={!!ticket ? ticket.range : 'first_day'}>
                  <TabsList className="flex flex-col h-full">
                    <div>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'first_day')}
                        value="first_day"
                      >
                        I dzień
                      </TabsTrigger>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'second_day')}
                        value="second_day"
                      >
                        II dzień
                      </TabsTrigger>
                      <TabsTrigger
                        onClick={() => form.setValue('range', 'any')}
                        value="any"
                      >
                        Dowolne
                      </TabsTrigger>
                    </div>
                    <TabsTrigger
                      onClick={() => form.setValue('range', 'full')}
                      value="full"
                    >
                      I dzień - II dzień
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              <hr className="my-4" />
              <FormField
                control={form.control}
                name="ticketTags"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tagi biletu</FormLabel>
                    <FormControl>
                      <MultipleSelector
                        value={ticketsTags}
                        onChange={setTicketsTags}
                        defaultOptions={ticketTags?.tags
                          .filter(({ type }) => type === 'DISCOUNT')
                          .map((tag) => ({
                            label: tag.name,
                            value: tag.id,
                          }))}
                        placeholder="Wybierz tagi biletu"
                        emptyIndicator={
                          <p className="text-center text-sm leading-10 text-gray-600 dark:text-gray-400">
                            Nie znaleziono tagów
                          </p>
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-between">
                {/* <p className="text-slate-500 text-xs font-normal leading-none flex items-center gap-x-1 mb-4">
                <Info className="w-3.5 h-3.5" />
                Ilość możliwych bietów do stworzenia:
                <span className="text-slate-900 font-medium">10 000</span>
              </p> */}
                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem className="w-28">
                      <FormLabel>Ilość</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Cena</FormLabel>
                      <div className="flex gap-x-2 w-full">
                        <FormControl className="w-24">
                          <Input {...field} />
                        </FormControl>
                        <FormDescription className="whitespace-nowrap w-40">
                          <Select>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a currency" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup defaultValue="pln">
                                <SelectItem value="pln">PLN</SelectItem>
                                <SelectItem value="usd">USD</SelectItem>
                                <SelectItem value="eur">EUR</SelectItem>
                                <SelectItem value="gbp">GBP</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormDescription>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* <hr className="my-4" />
                <div className="flex justify-between items-center">
                  <p className="font-medium leading-tight">
                    Opis{' '}
                    <span className="text-sm font-normal text-slate-500">
                      (opcjonalnie)
                    </span>
                  </p>
                  <DescriptionDialog />
                </div>
                <hr className="my-4" />
                <div className="flex justify-between items-center">
                  <p className="font-medium leading-tight">
                    Zdjęcie{' '}
                    <span className="text-sm font-normal text-slate-500">
                      (opcjonalnie)
                    </span>
                  </p>
                  <ImageDialog />
                </div> */}
              </div>
              <FormField
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem className="w-28">
                    <FormLabel>Źródło</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-end">
                <Button type="submit" className="bg-slate-900 text-white mt-4">
                  Zapisz bilet
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
