import { Edit, Trash } from 'lucide-react';
import { Button } from '../ui/button';

export const TicketDescription = () => {
  return (
    <div className="mt-16 flex justify-center">
      <div className="flex flex-col w-143">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-semibold leading-loose">Opis biletu</p>
          <div className="flex items-center text-slate-700">
            <Button
              variant="ghost"
              className="flex items-center gap-x-1 pl-1.5 py-1.5 pr-2"
            >
              <Edit className="w-4 h-4" />
              Edytuj
            </Button>
            <Button
              variant="ghost"
              className="flex items-center gap-x-1 pl-1.5 py-1.5 pr-2"
            >
              <Trash className="w-4 h-4" />
              Usuń
            </Button>
          </div>
        </div>
        <p className="mt-6 mb-4 text-xl leading-7">W cenie biletu</p>
        <ul className="space-y-4 list-disc list-inside">
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            pellentesque, nulla non cursus hendrerit, nisl mauris hendrerit
            urna, id lacinia nunc libero sit amet eros
          </li>
          <li>
            Nam sed massa luctus, venenatis orci vitae, hendrerit ipsum. Vivamus
            nec semper ligula. Nulla a semper enim, eget vestibulum urna
          </li>
          <li>Nam sed massa luctus</li>
        </ul>
      </div>
    </div>
  );
};
