import notFound from '@/assets/notFound.svg';
import { ReactElement } from 'react';

type Props = {
  description: string;
  element?: ReactElement;
};

export const NotFound = ({ description, element }: Props): ReactElement => {
  return (
    <div className="flex flex-col gap-y-4 justify-center items-center m-auto text-center">
      <img src={notFound} alt="File and magnifying glass icon" />
      <p className="text-lg font-medium text-slate-900 leading-7">
        {description}
      </p>
      {element}
    </div>
  );
};
