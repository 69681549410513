import { Event } from '@/types/event';
import { Order } from '@/types/order';
import { Ticket } from '@/types/ticket';
import { api } from './api';

const baseUrl = '/tickets';

const ticketsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTickets: builder.query<{ tickets: Ticket[] }, { eventId?: Event['id'] }>(
      {
        providesTags: ['Ticket'],
        query: ({ eventId }) => {
          return `${baseUrl}/events/${eventId}`;
        },
      },
    ),
    sendTicketsToClient: builder.mutation<void, { orderId: Order['id'] }>({
      query: ({ orderId }) => ({
        url: `/orders/${orderId}${baseUrl}-email`,
        method: 'POST',
      }),
    }),
    getTicketById: builder.query<
      { ticket: Ticket },
      { ticketId: Ticket['id'] }
    >({
      query: ({ ticketId }) => {
        return {
          url: `${baseUrl}/${ticketId}`,
          method: 'GET',
        };
      },
    }),
    createTicket: builder.mutation<
      { ticket: Ticket },
      Partial<Ticket & { tagsIds: string[] }>
    >({
      invalidatesTags: ['Ticket'],
      query: ({ tagsIds, ...body }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: { ...body, tagsIds },
      }),
    }),
    updateTicket: builder.mutation<{ ticket: Ticket }, Partial<Ticket>>({
      invalidatesTags: ['Ticket'],
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTicket: builder.mutation<void, { ticketId?: Ticket['id'] }>({
      invalidatesTags: ['Ticket'],
      query: ({ ticketId }) => ({
        url: `${baseUrl}/${ticketId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const ticketsApiEndpoints = ticketsApi.endpoints;
export const {
  useSendTicketsToClientMutation,
  useGetTicketsQuery,
  useCreateTicketMutation,
  useGetTicketByIdQuery,
  useUpdateTicketMutation,
  useDeleteTicketMutation,
} = ticketsApi;
