import { Order } from '@/types/order';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Calendar, CircleDot, File, MoreHorizontal } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ShortenStringClipboard } from '../ShortenStringClipboard';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

export const columns: ColumnDef<Order>[] = [
  {
    accessorKey: 'index',
    header: 'Pozycja',
    cell: ({ row }) => <span>{row.original.index}</span>,
  },
  {
    accessorKey: 'id',
    header: 'Numer zamówienia',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'email',
    header: 'Adres e-mail kupca',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClassName = '';
      let statusLabel = '';

      switch (row.original.status) {
        case 'awaiting':
          statusClassName = 'border-neutral-400 text-neutral-500';
          statusLabel = 'Oczekujące';
          break;
        case 'paid':
          statusClassName = 'border-cyan-600 text-cyan-600';
          statusLabel = 'Opłacone';
          break;
        case 'delivered':
          statusClassName = 'border-emerald-600 text-emerald-600';
          statusLabel = 'Dostarczone';
          break;
        case 'generated':
          statusClassName = 'border-neutral-400 text-neutral-500';
          statusLabel = 'Wygenerowane';
          break;
        case 'cancelled':
          statusClassName = 'border-red-600 text-red-600';
          statusLabel = 'Anulowane';
          break;
        case 'not_paid':
          statusClassName = 'border-yellow-500 text-yellow-500';
          statusLabel = 'Nieopłacone';
          break;
        case 'refund_requested':
          statusClassName = 'border-orange-500 text-orange-500';
          statusLabel = 'Żądanie zwrotu';
          break;
        case 'refunded':
          statusClassName = 'border-green-500 text-green-500';
          statusLabel = 'Zwrócone';
          break;
      }

      return (
        <p
          className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 `}
        >
          <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
        </p>
      );
    },
  },
  {
    accessorKey: 'orderDate',
    header: 'Data złożenia zamówienia',
    cell: ({ row }) => {
      const formattedOrderDate = format(
        new Date(row.original.createdAt),
        'dd LLLL yyyy HH:MM',
        { locale: pl },
      );

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          <p>{formattedOrderDate}</p>
        </span>
      );
    },
  },

  {
    accessorKey: 'paymentDate',
    header: 'Data opłacenia zamówienia',
    cell: ({ row }) => {
      const formattedPaymentDate = !!row?.original?.paymentDate
        ? format(new Date(row.original.paymentDate), 'dd LLLL yyyy HH:MM', {
            locale: pl,
          })
        : null;

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          <p>{formattedPaymentDate}</p>
        </span>
      );
    },
  },
  {
    accessorKey: 'price',
    header: 'Całkowita kwota',
    cell: ({ row }) => {
      const formattedPrice = row.original.totalPrice.toFixed(2);

      return (
        <p className="text-sm font-normal leading-tight">
          {formattedPrice}{' '}
          <span className="text-slate-500 text-xs font-normal leading-tight">
            PLN
          </span>
        </p>
      );
    },
  },
  {
    accessorKey: 'akcje',
    header: () => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        Akcje
      </div>
    ),
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="text-right">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-48 text-slate-900 text-sm font-medium leading-tight"
            >
              <DropdownMenuItem
                disabled
                className="text-slate-500 text-xs font-semibold leading-none"
              >
                Zobacz
              </DropdownMenuItem>
              <DropdownMenuItem className="w-full text-sm font-medium leading-tight">
                <Link to={`${row.original.id}`}>Szczegóły</Link>
              </DropdownMenuItem>
              {row?.original?.ticketsFile && (
                <DropdownMenuItem>
                  <Link
                    to={`https://storage.googleapis.com/dramoff-tickets/${row.original.ticketsFile}`}
                    target="_blank"
                    className="flex items-center w-full text-sm font-medium leading-tight"
                  >
                    <File className="w-4 h-4 mr-1" />
                    Dokument PDF
                  </Link>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
