import { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { useGetCurrentUserQuery } from './api/users';
import { Menu } from './components/menu/Menu';
import { MenuMobile } from './components/menu/MenuMobile';
import { useIsUserLoggedIn } from './slices/credentials/selectors';

export function App(): ReactElement {
  const isLoggedIn = useIsUserLoggedIn();
  const navigate = useNavigate();
  useGetCurrentUserQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    isLoggedIn && navigate('/wydarzenia');
  }, []);

  return (
    <ProtectedRoute>
      <div className="bg-slate-50 flex flex-col min-h-screen w-screen pb-[68px] sm:pb-0">
        <Menu />
        <MenuMobile />
        <Outlet />
      </div>
    </ProtectedRoute>
  );
}
