import { useGetOrdersByEventIdQuery } from '@/api/orders';
import { useSelectedEvent } from '@/slices/credentials/selectors';
import { SlidersHorizontal } from 'lucide-react';
import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../login/Loading';
import { DataTable } from './DataTable';
import { OrderStatusSelect } from './OrderStatusSelect';
import { columns } from './columns';
import { Input } from '../ui/input';
import { OrderFilterClearButton } from './OrderFilterClearButton';

export const OrdersView = (): ReactElement => {
  const event = useSelectedEvent();
  const { eventId } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [status, setStatus] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');

  const clearFilters = () => {
    setStatus('delivered');
    setEmail('');
    setOrderId('');

    // const newSearchParams = new URLSearchParams(searchParams);
    // newSearchParams.delete('status');
    // newSearchParams.delete('email');
    // newSearchParams.delete('id');
    // setSearchParams(newSearchParams);
  };

  const { data, isLoading } = useGetOrdersByEventIdQuery({
    eventId: eventId || event?.id,
    skip: (page - 1) * pageSize,
    take: pageSize,
    status,
    email,
    orderId,
  });
  if (isLoading) return <Loading />;

  const ordersData = (data?.orders ?? []).map((order, i) => ({
    ...order,
    index: (page - 1) * 10 + i + 1,
  }));
  const count = data?.count ?? 0;

  return (
    <div className="flex flex-col w-full">
      <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose ml-4 2xl:ml-10 mt-12 mb-7.5">
        Zamówienia
      </h1>
      <div className="mx-4 2xl:mx-10 mb-5 flex flex-col md:flex-row md:items-center gap-2.5">
        <div className="flex w-full justify-between items-center md:justify-start md:w-auto">
          <div className="flex gap-x-0.5 items-center">
            <SlidersHorizontal className="size-4" />
            <p className="text-slate-700 text-xs">Filtruj</p>
          </div>
          <div className="md:sr-only flex items-center">
            <OrderFilterClearButton clearFilters={clearFilters} />
          </div>
        </div>
        <Input
          type="text"
          placeholder="ID zamówienia"
          value={orderId}
          onChange={({ target }) => setOrderId(target.value)}
        />
        <Input
          type="text"
          placeholder="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
        <OrderStatusSelect
          selectedStatus={status}
          setSelectedStatus={setStatus}
        />
        <div className="sr-only md:not-sr-only flex items-center">
          <OrderFilterClearButton clearFilters={clearFilters} />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={ordersData}
        count={count}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};
