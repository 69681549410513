import { TicketGenerator } from '@/types/ticketGenerator';
import { api } from './api';

const baseUrl = '/ticket-generator';

const ticketGeneratorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTicketGenerator: builder.query<
      { ticketGenerator: TicketGenerator[] },
      { eventId: string }
    >({
      providesTags: ['TicketGenerator'],
      query: ({ eventId }) => `${baseUrl}/${eventId}`,
    }),
    createTicketGenerator: builder.mutation<
      { ticketGenerator: TicketGenerator },
      Partial<TicketGenerator>
    >({
      query: (body) => ({
        url: baseUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TicketGenerator'],
    }),
    updateTicketGenerator: builder.mutation<
      { ticketGenerator: TicketGenerator },
      {
        ticketGeneratorId: TicketGenerator['id'];
        body: Partial<TicketGenerator>;
      }
    >({
      query: ({ ticketGeneratorId, body }) => ({
        url: `${baseUrl}/${ticketGeneratorId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['TicketGenerator'],
    }),
    deleteTicketGenerator: builder.mutation<
      void,
      { ticketGeneratorId: TicketGenerator['id'] }
    >({
      query: ({ ticketGeneratorId }) => ({
        url: `${baseUrl}/${ticketGeneratorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketGenerator'],
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = ticketGeneratorApi.endpoints;
export const {
  useGetTicketGeneratorQuery,
  useCreateTicketGeneratorMutation,
  useUpdateTicketGeneratorMutation,
  useDeleteTicketGeneratorMutation,
} = ticketGeneratorApi;
