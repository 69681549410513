import clsx from 'clsx';
import { Calendar, Home } from 'lucide-react';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuUser } from './MenuUser';

export const MenuMobile = (): ReactElement => {
  const location = useLocation();

  return (
    <nav className="bg-white border-t border-slate-200 sm:sr-only fixed left-0 bottom-0 grid grid-cols-3 w-screen h-[68px]">
      <Link
        to="/"
        className={clsx(
          'flex flex-col justify-center items-center gap-y-1',
          location.pathname === '/'
            ? 'text-black bg-slate-100'
            : 'text-slate-700',
        )}
      >
        <Home
          className={clsx(
            'size-6 m-1',
            location.pathname === '/' ? 'stroke-black' : 'stroke-slate-700',
          )}
        />
        <p className="text-xs font-medium leading-tight">Dashboard</p>
      </Link>
      <Link
        to="/wydarzenia"
        className={clsx(
          'flex flex-col justify-center items-center gap-y-1',
          location.pathname === '/wydarzenia'
            ? 'text-black bg-slate-100'
            : 'text-slate-700',
        )}
      >
        <Calendar
          className={clsx(
            'size-6 m-1',
            location.pathname === '/wydarzenia'
              ? 'stroke-black'
              : 'stroke-slate-700',
          )}
        />
        <p className="text-xs font-medium leading-tight">Wydarzenia</p>
      </Link>
      <MenuUser />
    </nav>
  );
};
