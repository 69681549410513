import { Event } from '@/types/event';
import { api } from './api';

const eventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<{ events: Event[] }, void>({
      providesTags: ['Event'],
      query: () => `/events`,
    }),
    createEvent: builder.mutation<{ event: Event }, Partial<Event>>({
      invalidatesTags: ['Event'],
      query: (body) => ({
        url: `/events`,
        method: 'POST',
        body,
      }),
    }),
    getEventById: builder.query<{ event: Event }, { eventId?: Event['id'] }>({
      providesTags: ['Event'],
      query: ({ eventId }) => `/events/${eventId}`,
    }),
    updateEvent: builder.mutation<
      { event: Event },
      { eventId: Event['id']; body: Partial<Event> }
    >({
      invalidatesTags: ['Event'],
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteEvent: builder.mutation<void, { eventId: Event['id'] }>({
      invalidatesTags: ['Event'],
      query: ({ eventId }) => ({
        url: `/events/${eventId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const eventsApiEndpoints = eventsApi.endpoints;
export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useGetEventByIdQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
} = eventsApi;
