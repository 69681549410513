import { Order } from '@/types/order';
import { Calendar, CircleDot } from 'lucide-react';
import { ReactElement } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';

export const OrderAccordion = ({ order }: { order: Order }): ReactElement => {
  let statusClassName = '';
  let statusLabel = '';
  let status = order.status;

  switch (status) {
    case 'awaiting':
      statusClassName = 'border-neutral-400 text-neutral-500';
      statusLabel = 'Oczekujące';
      break;
    case 'paid':
      statusClassName = 'border-cyan-600 text-cyan-600';
      statusLabel = 'Opłacone';
      break;
    case 'delivered':
      statusClassName = 'border-emerald-600 text-emerald-600';
      statusLabel = 'Dostarczone';
      break;
    case 'generated':
      statusClassName = 'border-neutral-400 text-neutral-500';
      statusLabel = 'Wygenerowane';
      break;
    case 'cancelled':
      statusClassName = 'border-red-600 text-red-600';
      statusLabel = 'Anulowane';
      break;
    case 'not_paid':
      statusClassName = 'border-yellow-500 text-yellow-500';
      statusLabel = 'Nieopłacone';
      break;
    case 'refund_requested':
      statusClassName = 'border-orange-500 text-orange-500';
      statusLabel = 'Żądanie zwrotu';
      break;
    case 'refunded':
      statusClassName = 'border-green-500 text-green-500';
      statusLabel = 'Zwrócone';
      break;
  }

  return (
    <>
      <Accordion type="multiple" className="w-106">
        <AccordionItem value="item-1">
          <AccordionTrigger className="text-slate-700 text-xl font-medium leading-7">
            Dane zamówienia
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-y-4">
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Status
              </p>
              <p
                className={`${statusClassName} inline-flex items-center text-sm gap-x-1 px-2 py-1 rounded-md border border-1 `}
              >
                <CircleDot className="w-2.5 h-2.5" /> {statusLabel}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Data złożenia zamówienia
              </p>
              <span className="flex items-center gap-x-1">
                <Calendar className="w-4 h-4 text-slate-700" />
                <p>{new Date(order.createdAt).toLocaleString()}</p>
              </span>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Data opłacenia zamówienia
              </p>
              <span className="flex items-center gap-x-1">
                {order?.paymentDate &&
                  new Date(order.paymentDate).toLocaleString('pl')}
              </span>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Wysyłka na adres
              </p>

              {!!order?.address && !Object.keys(order?.address)?.length ? (
                <p className="text-slate-500 text-sm font-normal leading-tight">
                  Brak
                </p>
              ) : (
                <p className="text-sm font-normal leading-tight">
                  {order?.address?.street}{' '}
                  {!!order?.address?.flatNumber
                    ? order?.address?.homeNumber + '/'
                    : order?.address?.homeNumber}
                  {order?.address?.flatNumber} <br />
                  {order?.address?.city}&nbsp;
                  {order?.address?.postalCode} <br />
                  {order?.address?.country}
                </p>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger className="text-slate-700 text-xl font-medium leading-7">
            Dane zamawiającego
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-y-4">
            <p className="text-slate-700 text-base font-semibold leading-normal mb-3">
              Dane osobowe
            </p>
            <div className="mb-4">
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Imię i nazwisko
              </p>
              <p className="text-sm font-normal leading-tight">
                {order?.recipientFullName ?? ''}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Adres e-mail
              </p>
              <p className="text-sm font-normal leading-tight">
                {order?.email ?? ''}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Phone number
              </p>
              <p className="text-sm font-normal leading-tight">
                {order?.phoneNumber ?? ''}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                Business name
              </p>
              <p className="text-sm font-normal leading-tight">
                {order?.businessName ?? ''}
              </p>
            </div>
            <div>
              <p className="text-slate-500 text-xs font-normal leading-none mb-1">
                TAX ID
              </p>
              <p className="text-sm font-normal leading-tight">{order.nip}</p>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
