import { useGetEventByIdQuery } from '@/api/events';
import { useGetAllHorecaQuery } from '@/api/horeca';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../NotFound';
import { DataTable } from '../eventsView/DataTable';
import { Loading } from '../login/Loading';
import { columns } from './columns';

export const Horeca = (): ReactElement => {
  const params = useParams();
  const eventId = params['eventId'];

  const { data: eventData } = useGetEventByIdQuery(
    { eventId },
    { skip: !eventId },
  );

  const { data, isLoading } = useGetAllHorecaQuery(
    {
      eventId: eventId!,
    },
    { skip: !eventId },
  );

  const horeca = data?.horecaFormMessages?.length;

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          HoReCa
        </h1>
      </div>
      {horeca ? (
        !!eventData?.event && (
          <DataTable
            columns={columns(eventData?.event)}
            data={data?.horecaFormMessages ?? []}
          />
        )
      ) : (
        <NotFound description="Nie znaleziono żadnych danych HoReCa" />
      )}
    </div>
  );
};
