import { Horeca } from '@/types/horeca';
import { trimId } from '@/utils/trimId';
import { ColumnDef } from '@tanstack/react-table';
import { Calendar } from 'lucide-react';
import { ShortenStringClipboard } from '../ShortenStringClipboard';

import { Event } from '@/types/event';
import { pl } from 'date-fns/locale';
import { format } from 'date-fns';

export const columns = (event: Event): ColumnDef<Horeca>[] => [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <ShortenStringClipboard
        content={row.original.id}
        argName="ID"
        trimCallback={trimId}
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    header: 'Data zgłoczenia',
    cell: ({ row }) => {
      const formattedOrderDate = format(
        new Date(row.original.createdAt),
        'dd LLLL yyyy HH:MM',
        { locale: pl },
      );

      return (
        <span className="flex items-center gap-x-1">
          <Calendar className="w-4 h-4 text-slate-700" />
          <p>{formattedOrderDate}</p>
        </span>
      );
    },
  },
  {
    accessorKey: 'representative',
    header: 'Reprezentant firmy',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'companyName',
    header: 'Nazwa firmy',
  },
  {
    accessorKey: 'taxId',
    header: 'NIP',
  },
  {
    accessorKey: 'quantity',
    header: 'Bilety',
  },
  // {
  //   accessorKey: 'akcje',
  //   header: () => (
  //     <div
  //       style={{
  //         textAlign: 'right',
  //       }}
  //     >
  //       Akcje
  //     </div>
  //   ),
  //   enableHiding: false,
  //   cell: ({ row }) => {
  //     const [deleteHoreca] = useDeleteHorecaMutation();

  //     return (
  //       <div className="text-right">
  //         <DropdownMenu>
  //           <DropdownMenuTrigger asChild>
  //             <Button variant="ghost" className="h-8 w-8 p-0">
  //               <span className="sr-only">Open menu</span>
  //               <MoreHorizontal className="h-4 w-4" />
  //             </Button>
  //           </DropdownMenuTrigger>
  //           <DropdownMenuContent>
  //             <DropdownMenuItem
  //               disabled
  //               className="text-slate-500 text-xs font-semibold leading-none"
  //             >
  //               Opcje
  //             </DropdownMenuItem>
  //             <ConfirmationModal
  //               type="delete"
  //               title="Czy na pewno chcesz usunąć ten bilet?"
  //               description="Ta operacja nie może być cofnięta. Spowoduje to trwałe usunięcie
  //             Twojego biletu i usunięcie związanych z nim danych z naszych
  //             serwerów."
  //               onConfirm={() =>
  //                 deleteHoreca({
  //                   horecaId: row.original.id,
  //                   eventId: event.id,
  //                 })
  //               }
  //             >
  //               <Button
  //                 variant="ghost"
  //                 className="px-2 w-full justify-start text-red-500 hover:text-red-500"
  //               >
  //                 <Trash className="w-4 h-4 mr-1" />
  //                 Usuń
  //               </Button>
  //             </ConfirmationModal>
  //           </DropdownMenuContent>
  //         </DropdownMenu>
  //       </div>
  //     );
  //   },
  // },
];
