import { useGetTicketsQuery } from '@/api/tickets';
import { useSelectedEvent } from '@/slices/credentials/selectors';
import { ReactElement } from 'react';
import { NotFound } from '../NotFound';
import { columns } from '../eventView/columns';
import { Loading } from '../login/Loading';
import { AddEditTicket } from '../ticket/AddEditTicket';
import { DataTable } from './DataTable';

export const TicketView = (): ReactElement => {
  const event = useSelectedEvent();
  const { data, isLoading } = useGetTicketsQuery(
    {
      eventId: event?.id ?? '',
    },
    { skip: !event },
  );
  const tickets = data?.tickets?.length;

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mx-4 2xl:mx-10 mt-12 mb-8">
        <h1 className="leading-7 text-lg sm:text-2xl font-semibold sm:leading-loose">
          Bilety
        </h1>
        {!!tickets && <AddEditTicket light />}
      </div>
      {tickets ? (
        <DataTable columns={columns} data={data?.tickets ?? []} />
      ) : (
        <NotFound
          description="Nie znaleziono żadnego biletu"
          element={<AddEditTicket />}
        />
      )}
    </div>
  );
};
