import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { Loading } from './components/login/Loading';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { login } from './slices/credentials/credentialsSlice';
import { useLogout } from './hooks/useLogout';
import { useIsUserLoggedIn } from './slices/credentials/selectors';

export const ProtectedRoute = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();
  const logout = useLogout();

  const isLoggedIn = useIsUserLoggedIn();

  if (!isLoading && isAuth0Authenticated) {
    if (isLoggedIn) return children;

    const authPromise = new Promise<string>(async (res) => {
      const auth0Token = await getAccessTokenSilently();
      res(auth0Token);
    });

    authPromise.then(async (auth0Token: string) => {
      try {
        const { data, status } = await axios.post<{ accessToken: string }>(
          `${import.meta.env.VITE_API_URL}/auth/jwt`,
          {
            token: auth0Token,
          },
        );

        if (status !== 201) throw new Error('Unauthorized');
        const { accessToken } = data;

        dispatch(login({ accessToken, auth0Token }));
      } catch (error) {
        await logout();
      }
    });

    if (isLoggedIn) return children;
  }

  if (!isLoading && !isAuth0Authenticated) logout();

  return <Loading />;
};
